import React from "react";
import { Grid, Avatar, Typography } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    zIndex: 1,
    border: `0.1px solid lightgray`,
  },
}));

const Testimonial = ({ text, image, name }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <React.Fragment>
      <Grid container xs={9}>
        <Typography>
          <p>
            <i>"{text}"</i>
          </p>
        </Typography>
      </Grid>
      <Grid container xs={3} justify="flex-end" alignContent="center">
        <div
          style={{
            position: "relative",
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
        >
          <Avatar className={classes.avatar} src={image} />
          <div
            style={{
              zIndex: 2,
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: "100%",
              width: "100%",
            }}
          >
            <Typography
              style={{
                transform: "rotate(-32deg)",
                position: "absolute",
                right: 0,
                bottom: 0,
                fontSize: "0.7em",
              }}
            >
              {name}
            </Typography>
          </div>
          {/* <Typography style={{ zIndex: 2, position: 'absolute', top: '50%', left: '50%', fontSize: '0.8em', transform: 'translate(-50%,-50%)' }}><p style={{ transform: 'rotate(-30deg)' }}>Oli.P</p></Typography> */}
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default Testimonial;
