import React from "react";
import TagManager from "react-gtm-module";

const getTag = (domainName) => {
  const defaultTag = "GTM-WQM78GB";
  if (!domainName) return defaultTag;
  const parts = domainName.split(".");
  const topLevelDomain = parts.pop() || "de";
  switch (topLevelDomain) {
    case "com":
      return "GTM-N537K75";
    case "de":
    default:
      return defaultTag;
  }
};

const tagManagerArgs = {
  gtmId: getTag(window.location.hostname),
};
TagManager.initialize(tagManagerArgs);

const TrackingWrapper = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default TrackingWrapper;
