const errorCodes = {
    generic: {
        ROUTE_DEPRECATED: 'route_deprecated'
    },
    auth: {
        EMAIL_IS_MISSING: 'email_is_missing',
        EMAIL_NOT_VALID: 'email_not_valid',
        PASSWORD_IS_MISSING: 'password_is_missing',
        PASSWORD_NOT_VALID: 'password_not_valid',
        USER_NOT_FOUND: 'user_not_found',
        USER_ALREADY_REGISTERED: 'user_already_registered',
        CHALLENGE_ID_NOT_FOUND: 'challenge_id_not_found',
        CHALLENGE_ID_EXPIRED: 'challenge_id_expired',
        NO_MATCHING_PROVIDER_APPLICATION: 'no_matching_provider_application',
        PROVIDER_APPLICATION_NOT_APPROVED: 'provider_application_not_approved',
        MISSING_PROVIDER_ID: 'missing_provider_id',
        PROVIDER_NOT_FOUND: 'provider_not_found',
        EMAIL_ALREADY_EXISTS: 'email_already_exists',
        EMAIL_ALREADY_REGISTERED_AS_PROVIDER: 'email_already_registered_as_provider'
    }
}

export { errorCodes }
