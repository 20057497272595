import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TeaserCard from "./TeaserCard";
import Testimonial from "./Testimonial";
import { useTranslation } from "react-i18next";
import InlineFooter from "../../components/InlineFooter";
import { destinations } from "../ActyvystDestination/destinations";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    border: `0.1px solid lightgray`,
  },
  mainContainer: {
    paddingBottom: 60,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 60,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 60,
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
    backgroundColor: "white",
    minHeight: "100%",
    width: "100%",
    height: "auto",
    minWidth: "100%",
  },
  teaserText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2em",
    },
    textAlign: "left",
  },
  gridContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    // [theme.breakpoints.down('sm')]: {
    //     paddingLeft: 0,
    //     paddingRight: 0,
    // },
    // [theme.breakpoints.up('sm')]: {
    //     paddingLeft: theme.spacing(4),
    //     paddingRight: theme.spacing(4),
    // }
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const AppLanding = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const spacing = isMobile ? 1 : 4;

  const createTeaserAction = ({ type, link, destination }) => {
    return () => {
      switch (type) {
        case "onWebLink":
          window.dataLayer.push({
            event: "selectWebIBE",
            destination,
          });
          window.open(link);
          break;
        case "onPlaystoreLink":
          window.dataLayer.push({
            event: "selectDownloadTeaser",
            destination,
            os: "android",
          });
          window.open(link);

          break;
        case "onAppstoreLink":
          window.open(link);
          window.dataLayer.push({
            event: "selectDownloadTeaser",
            destination,
            os: "ios",
          });
          break;
        case "onProviderLink":
          window.dataLayer.push({
            event: "selectForProviders",
            destination,
          });
          window.location = link;
          break;
        default:
          return;
      }
    };
  };

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        {/* <Typography>isMobile: {JSON.stringify(isMobile)}</Typography>
            <Typography>isMedium: {JSON.stringify(isMedium)}</Typography> */}
        <Grid
          container
          spacing={spacing}
          style={{
            height: "auto",
            minHeight: "100%",
            width: "100%",
            minWidth: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            className={classes.gridContainer}
            style={{ paddingLeft: 0, paddingRight: isMobile ? 0 : undefined }}
          >
            <Typography style={{ textAlign: "left", fontSize: "1.2em" }}>
              {/* <Typography>isMobile:{JSON.stringify(isMobile)}</Typography>
                        <Typography>isMedium:{JSON.stringify(isMedium)}</Typography> */}
              <p>{t("AppLanding.teaser")}</p>
              <p>{t("AppLanding.downloadNow")} </p>
            </Typography>
          </Grid>
          {!isMobile ? (
            <React.Fragment>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={4}
                className={classes.gridContainer}
                style={{
                  paddingLeft: isMobile ? 0 : undefined,
                  paddingRight: isMobile || isMedium ? 0 : undefined,
                }}
              >
                <Testimonial
                  text={t("AppLanding.testimonials.0.text")}
                  image={t("AppLanding.testimonials.0.image")}
                  name={t("AppLanding.testimonials.0.name")}
                />
                <Testimonial
                  text={t("AppLanding.testimonials.1.text")}
                  image={t("AppLanding.testimonials.1.image")}
                  name={t("AppLanding.testimonials.1.name")}
                />
              </Grid>
              {isMedium ? undefined : (
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className={classes.gridContainer}
                  style={{
                    paddingLeft: isMobile || isMedium ? 0 : undefined,
                    paddingRight: !isMedium ? 0 : undefined,
                  }}
                >
                  <Testimonial
                    text={t("AppLanding.testimonials.2.text")}
                    image={t("AppLanding.testimonials.2.image")}
                    name={t("AppLanding.testimonials.2.name")}
                  />
                  <Testimonial
                    text={t("AppLanding.testimonials.3.text")}
                    image={t("AppLanding.testimonials.3.image")}
                    name={t("AppLanding.testimonials.3.name")}
                  />
                </Grid>
              )}
            </React.Fragment>
          ) : undefined}

          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{ paddingLeft: 0, paddingRight: isMobile ? 0 : undefined }}
          >
            <TeaserCard
              image={{ url: "/img/thumb-ruegen.jpg", altText: "Image" }}
              destination={destinations["ruegen"]?.name}
              onWebLink={createTeaserAction({
                type: "onWebLink",
                destination: "ruegen",
                link: "https://ruegen.actyvyst.de",
              })}
              onAppstoreLink={createTeaserAction({
                type: "onAppstoreLink",
                destination: "ruegen",
                link: "https://itunes.apple.com/de/app/actyvyst/id1550101140",
              })}
              onPlaystoreLink={createTeaserAction({
                type: "onPlaystoreLink",
                destination: "ruegen",
                link: "https://play.google.com/store/apps/details?id=com.actyvyst.ruegen",
              })}
              onProviderLink={createTeaserAction({
                type: "onProviderLink",
                destination: "ruegen",
                link: "/destinations/ruegen",
              })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{
              paddingLeft: isMobile ? 0 : undefined,
              paddingRight: isMobile || isMedium ? 0 : undefined,
            }}
          >
            <TeaserCard
              image={{ url: "/img/thumb-sylt.jpg", altText: "Image" }}
              destination={destinations["sylt"]?.name}
              onWebLink={createTeaserAction({
                type: "onWebLink",
                destination: "sylt",
                link: "https://sylt.actyvyst.de",
              })}
              onAppstoreLink={createTeaserAction({
                type: "onAppstoreLink",
                destination: "sylt",
                link: "https://itunes.apple.com/de/app/actyvyst/id1465082431",
              })}
              onPlaystoreLink={createTeaserAction({
                type: "onPlaystoreLink",
                destination: "sylt",
                link: "https://play.google.com/store/apps/details?id=com.actyvystsylt",
              })}
              onProviderLink={createTeaserAction({
                type: "onProviderLink",
                destination: "sylt",
                link: "/destinations/sylt",
              })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{
              paddingLeft: isMobile || isMedium ? 0 : undefined,
              paddingRight: !isMedium ? 0 : undefined,
            }}
          >
            <TeaserCard
              image={{ url: "/img/thumb-mallorca.png", altText: "Image" }}
              destination={destinations["mallorca"]?.name}
              onWebLink={createTeaserAction({
                type: "onWebLink",
                destination: "mallorca",
                link: "https://mallorca.actyvyst.com",
              })}
              onAppstoreLink={createTeaserAction({
                type: "onAppstoreLink",
                destination: "mallorca",
                link: "https://itunes.apple.com/de/app/actyvyst/id1565782272",
              })}
              onPlaystoreLink={createTeaserAction({
                type: "onPlaystoreLink",
                destination: "mallorca",
                link: "https://play.google.com/store/apps/details?id=com.actyvyst.mallorca",
              })}
              onProviderLink={createTeaserAction({
                type: "onProviderLink",
                destination: "mallorca",
                link: "/destinations/mallorca",
              })}
            />
          </Grid>
          {isMobile && (
            <Grid
              container
              item
              xs={12}
              className={classes.gridContainer}
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <Testimonial
                text={t("AppLanding.testimonials.0.text")}
                image={t("AppLanding.testimonials.0.image")}
                name={t("AppLanding.testimonials.0.name")}
              />
              <Testimonial
                text={t("AppLanding.testimonials.1.text")}
                image={t("AppLanding.testimonials.1.image")}
                name={t("AppLanding.testimonials.1.name")}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <InlineFooter visible={true} showContact={false} />
    </React.Fragment>
  );
};

export default AppLanding;
