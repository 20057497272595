class ActyvystAPIError extends Error {
    constructor(msg, status, code) {
        super(msg);
        this.name = this.constructor.name;
        this.status = status;
        this.code = code;
    }
    static createFromAxiosError(axiosError) {
        const { response } = axiosError;
        if (!response || !response.status) { return axiosError }
        const status = response.status;
        const code = response.data &&
            response.data.errors &&
            response.data.errors[0] &&
            response.data.errors[0].code ? response.data.errors[0].code : null;
        const msg = response.data &&
            response.data.errors &&
            response.data.errors[0] &&
            response.data.errors[0].title ? response.data.errors[0].title : `actyvyst API returned ${status}`;
        return new ActyvystAPIError(msg, status, code);
    }
}

export { ActyvystAPIError }