import React from 'react';
import { Grid } from "@material-ui/core";
import { useStyles } from './styles';
import { useTranslation } from "react-i18next";
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

const ContactStep = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container >
            <Grid item xs={12} sm={6} className={classes.fieldContainer} >
                <Field
                    required
                    component={TextField}
                    name="contact.firstName"
                    label={t('ProviderRegistration.labels.firstName')}
                    variant="outlined"
                    className={classes.field}
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.fieldContainer}>
                <Field
                    required
                    component={TextField}
                    name="contact.lastName"
                    label={t('ProviderRegistration.labels.lastName')}
                    variant="outlined"
                    className={classes.field}
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer} >
                <Field
                    required
                    type="tel"
                    component={TextField}
                    name="contact.phone"
                    label={t('ProviderRegistration.labels.phone')}
                    variant="outlined"
                    className={classes.field}
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer} >
                <Field
                    required
                    component={TextField}
                    name="contact.email"
                    type="email"
                    label={t('ProviderRegistration.labels.email')}
                    variant="outlined"
                    className={classes.field}
                    inputProps={{ maxLength: 50 }}
                />
            </Grid>
            {/* <Grid item xs={12} className={classes.fieldContainer} >
                <Field
                    required
                    component={TextField}
                    name="contact.password"
                    type="password"
                    label={t('ProviderRegistration.labels.password')}
                    variant="outlined"
                    className={classes.field}
                    inputProps={{ maxLength: 30 }}
                />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
                <Field
                    required
                    component={TextField}
                    type="password"
                    label={t('ProviderRegistration.labels.confirmPassword')}
                    name="contact.confirmPassword"
                    variant="outlined"
                    className={classes.field}
                    inputProps={{ maxLength: 30 }}
                />
            </Grid> */}
        </Grid>
    )
}

export default ContactStep;