const destinations = {
    sylt: {
        name: 'Sylt',
        status: 'live',
        bg: `${process.env.PUBLIC_URL}/img/actyvyst-sylt-bg.jpg`,
    },
    allgaeu: {
        name: 'Allgäu',
        status: 'comingSoon',
        bg: `${process.env.PUBLIC_URL}/img/actyvyst-allgaeu-bg.jpg`,
    },
    texel: {
        name: 'Texel',
        status: 'comingSoon',
        bg: `${process.env.PUBLIC_URL}/img/actyvyst-texel-bg.jpg`,
    },
    ruegen: {
        name: 'Rügen',
        status: 'live',
        bg: `${process.env.PUBLIC_URL}/img/actyvyst-ruegen-bg.jpg`,
    },
    mallorca: {
        name: 'Mallorca',
        status: 'comingSoon',
        bg: `${process.env.PUBLIC_URL}/img/actyvyst-mallorca-bg.jpg`
    }
}

export { destinations };