import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { TextField, Select } from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";

const countries = [
  "Deutschland",
  "Frankreich",
  "Italien",
  "Niederlande",
  "Spanien",
];

const CompanyStep = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Field
          required
          component={TextField}
          name="provider.name"
          label={t("ProviderRegistration.labels.provider")}
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Field
          required
          component={Select}
          style={{ margin: 0 }}
          name="provider.address.country"
          variant="outlined"
          className={classes.field}
          button={false}
          inputProps={{
            id: "provider.address.country",
          }}
        >
          {countries.map((country) => {
            return (
              <MenuItem style={{ backgroundColor: "white" }} value={country}>
                {t(`countries.${country}`)}
              </MenuItem>
            );
          })}
        </Field>
      </Grid>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Field
          required
          component={TextField}
          label={t("ProviderRegistration.labels.street")}
          name="provider.address.street"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={classes.fieldContainer}>
        <Field
          required
          component={TextField}
          label={t("ProviderRegistration.labels.zip")}
          name="provider.address.zip"
          variant="outlined"
          className={classes.field}
          inputProps={{ inputMode: "numeric", maxLength: 5 }}
        />
      </Grid>
      <Grid item xs={12} sm={8} className={classes.fieldContainer}>
        <Field
          required
          component={TextField}
          label={t("ProviderRegistration.labels.city")}
          name="provider.address.city"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Field
          type="url"
          component={TextField}
          label={t("ProviderRegistration.labels.web")}
          name="provider.web"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 50 }}
        />
      </Grid>
      <Grid item xs={12} className={classes.fieldContainer}>
        <Field
          component={TextField}
          multiline
          rowsMax={4}
          rows={4}
          label={t("ProviderRegistration.labels.description")}
          name="provider.description"
          variant="outlined"
          className={classes.field}
          inputProps={{ maxLength: 200 }}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyStep;
