const en = {
  translation: {
    "Mobile Lösungen": "Mobile solutions",
    "agencyTeaser Line1": "Full service agency - ",
    "agencyTeaser Line2": "we build apps and mobile websites.",
    "actyvyst GO": "actyvyst GO",
    "actyvystTeaser Line1": "Your digital holiday companion.",
    "actyvystTeaser Line2": "Now availalable for Sylt!",
    "HomeBanner Agency Teaser Line1":
      "You are looking for a professional software development partner?",
    "Home Banner actyvyst Go Teaser Line 1":
      "Your business is offering tours, events or activities and you want to know more about actyvyst GO?",
    "Home Banner actyvyst Go Teaser Line 2":
      "You are working for a DMO and you are interested in actyvyst GO and its benefits for your destination?",
    "Nehmen Sie Kontakt mit uns auf!": "Get in touch with us!",
    navigation: {
      Home: "Home",
      "actyvyst GO": "actyvyst GO",
      Mobile: "Mobile Solutions",
      forProviders: "For Providers",
      Sylt: "actyvyst Sylt",
      Kontakt: "Contact",
      Impressum: "Imprint",
      Datenschutz: "Data Protection"
    },
    // "navigation.Home": "Home",
    // "navigation.actyvyst GO": "actyvyst GO",

    // "navigation.Kontakt": "Contact",
    // "navigation.Impressum": "Imprint",
    // "navigation.Datenschutz": "Data Security",
    "Sie haben Fragen?": "Do you have any questions? Feel free to contact us:",
    "actyvyst GO Intro":
      "Local apps for local providers: actyvyst GO is our product for digital distribution of leisure activities.",
    "actyvyst GO Screenshot 1 Title": "Activity list",
    "actyvyst GO Screenshot 2 Title": "Todays picks",
    "actyvyst GO Screenshot 3 Title": "Voucher view",
    "actyvyst GO Screenshot 4 Title": "Destination quiz",
    "actyvyst GO Screenshot 5 Title": "Register and Login",
    "Der digitale Freizeitguide": "The digital holiday companion",
    "actyvyst description 1":
      "Find all leisure offers at your holiday destination with the actyvyst GO app - no matter if you are looking for surf lessons, a horse riding trip or a ticket for the theatre.",
    "actyvyst description 2":
      "We plan to deliver dedicated apps for each holiday destination - accompanied by online and offline marketing campaigns directly on site - since this is the point and moment of decision for most travellers to plan their activities for the next days of their holidays.",
    "actyvyst description 3":
      "Most activities can be booked and paid directly within the app.",
    "actyvyst description 4":
      "In Summer 2019, we successfully launched actyvyst GO on the german island Sylt.",
    "Jetzt testen auf Android oder iOS": "Try out now on Android or iOS: ",
    "Jetzt im App Store laden": "Download now from App Store",
    "Jetzt im Google Play Store laden": "Download now from Google Play Store",
    "mobile solutions Intro 1":
      "We plan, develop and operate apps and responsive websites according to your requirements and needs.",
    "mobile solutions Intro 2":
      "Our experienced team supports you in all phases of the project - from the original idea to the production launch of your product.",
    "mobile solutions Strategy Headline": "Strategy & Planning",
    "mobile solutions Strategy Text":
      "No matter if you have a fuzzy idea or a specification book: we support you developing the feature set for your product and validate the feasability.",
    "mobile solutions ux Headline": "UX & UI",
    "mobile solutions ux Text":
      "We use prototyping and wireframing to visualize the look & feel of your product at the earliest possible stage.",
    "mobile solutions apis Headline": "Interfaces & Data Sources",
    "mobile solutions apis Text":
      "Usually, an app is connected to data: we care for a smooth and safe connection to your data resources.",
    "mobile solutions dev Headline": "Development & Launch",
    "mobile solutions dev Text":
      "We structure our development in short iterations to have working interim results to discuss with our customers. This allows us to react fast on changing requirements or new priorities - and it allows you to be permanently up to date on your product.",
    "mobile solutions maintenance Headline": "Operations & Support",
    "mobile solutions maintenance Text":
      "Operating and monitoring your application is a vital part of our business. Regular updates keep your product fresh.",
    Referenzen: "Recent projects",
    "Referenzen Intro": "Take a look at our latest projects!",
    "Ref actyvyst Sylt Headline":
      "actyvyst Sylt - your digital holiday companion",
    "Ref actyvyst Sylt Description":
      "<b>actyvyst Sylt</b> provides an extensive overview of almost all activities available on the island of Sylt: water sports, nature experiences, cultural highlights, wellness facilities, exclusive shops and boutiques and a huge variety of restaurants will make your holiday unique.  <br /><br />Detailed information about location and reachability simplifies getting in contact with the providers.<br />There are plenty of vouchers available for a lot of activities which you can redeem directly at the providers.<br /><br />The app is available for Android and iOS: <br /><br />",
    "Ref uparking Headline": "UParking",
    "Ref uparking Description":
      "<b>UParking</b> is a handy little helper in everyday life. This app for iPhones lets you save and find your last parking position. <br />You can also share the parking position as a map link. <br />Available for iOS: <br /> <br />",
    "Ref teamshaker Headline": "TeamShaker",
    "Ref teamshaker Description":
      "<b>TeamShaker</b> is a tool to create two or more teams from one or more pools of individually defined team members - simply by shaking your iPhone. Team members have a strength attribute which is taken into account when creating the teams - this allows to create balanced teams in a random manner. <br/>The perfect tool for any kind of team sports, team work in school etc.<br />Available for iOS: <br /> <br />",
    "Ref escapebox Headline": "Escape Box",
    "Ref escapebox Description":
      "This iPad App acts as the master of ceremonies for an interactive educational escape room game, created by jaeger + haeckerhase GmbH, a marketing agency from Duesseldorf.<br /><b>Escape Box</b> works as the 'pulse' of the game – main timer and multimedia support for solving the game's riddles.",

    Kontakt: "Contact",
    "Kontakt Text":
      "Get in touch with us, we are looking forward to receiving your message!",
    Impressum: "Imprint",
    "managing directors": "Managing directors: {{names}}",
    "commercial register": "Commercial register: {{entry}}",
    "vat number": "VAT number: {{entry}}",
    "Inhaltliche Verantwortung": "Responsible for content: ",
    "actyvyst.de verwendet Cookies": "actyvyst.de is using cookies",
    "Mehr erfahren": "Details...",
    "actyvyst-go-details-pdf":
      "Download detailed information on actyvyst GO (PDF)",
    countries: {
      Deutschland: "Germany",
      Frankreich: "France",
      Italien: "Italy",
      Niederlande: "Netherlands",
      Spanien: "Spain"
    },
    generic: {
      validation: {
        required: "This field is required",
        max: "Please enter at most {{max}} characters.",
        min: "Please enter at least {{min}} characters"
      }
    },
    ForProviders: {
      registerButton: "Register as provider",
      loginButton: "Login for registered providers",
      downloadPDFTitle: `Summary for activity providers as`,
      downloadPDFLinkText: `PDF download`,
      subtitlePDF: `Summary for activity providers`,
      bulletPointsPDF: `In a nutshell: what is it about?  
What's in for me as a provider of activities?  
How does the app work?  
How can I provide my content?  
I want to participate. What should I do?  `,
      titleQA: "Questions & Answers",
      titleContact: "actyvyst provider support {{destination}}:",
      contact: {
        sylt: `Ralf Siewert  
                [+49 (0)160 921 801 31](tel:+4916092180131)  
                [ralf.siewert(at)actyvyst.com](mailto:ralf.siewert@actyvyst.com)  `,
        ruegen: `Ralf Siewert  
                [+49 (0)160 921 801 31](tel:+4916092180131)  
                [ralf.siewert(at)actyvyst.com](mailto:ralf.siewert@actyvyst.com)  `,
        mallorca: `Elena Kempf  
                [+34 639 878 090](tel:+34639878090)  
                [elena.kempf(at)actyvyst.com](mailto:elena.kempf@actyvyst.com)  `
      }
    },

    AppLanding: {
      teaser:
        "The actyvyst apps offer you a great overview of all the activities that you can do at your holiday destination.",
      downloadNow: "Download and try now:",
      forProviders: "For providers",
      browseActivities: "Browse activities",
      testimonials: [
        {
          text: "Outstanding offers in the actyvyst App! I would never have searched for something like this. Didn‘t even think they‘d exist.",
          image: "/img/testimonial_OliP.png",
          name: "Oli.P"
        },
        {
          text: "I did the standard sightseeing before. On actyvyst I found some really cool adventures for my gang of little rascals",
          image: "/img/testimonial_InaUndAni.jpg",
          name: "Ina G."
        },
        {
          text: "Apps are sometimes complicated. But with actyvyst I get along really well.",
          image: "/img/testimonial_Ulla.png",
          name: "Ulla K."
        },
        {
          text: "As a provider, I come into contact with people who would otherwise never have found me.",
          image: "/img/testimonial_Leo.png",
          name: "Leo K."
        }
      ]
    },
    ResetPassword: {
      title: "Reset password",
      mainText: `Please enter your new password and click "Use new password". Afterwards you can sign in with your new password.`,
      successText:
        "Your password has been changed. You can now sign in with your new password",
      errorText: `An unexpected error occured when changing your password.`,
      errorTextTimeout: `Unfortunately the time period for changing your password has already expired.
      You can trigger the password change again by clicking "Change Password".`,
      validation: {
        confirmPassword: `The passwords don't match`,
        pin: "Please enter your 4-digit code"
      },
      labels: {
        email: "e-mail",
        pin: "4-digit code",
        password: "New password",
        confirmPassword: "Repeat new password"
      },
      buttons: {
        home: "Home",
        submit: "Use new password"
      }
    },
    ForgotPassword: {
      title: "Forgot password?",
      mainText: `Please enter your e-mail address and select "Reset password".
      We will then send you an e-mail with a link to reset your password.`,
      successText: `An e-mail with instructions to reset your password has been sent to the given address.`,
      errorText: `Your password cannot be reset. Please verify if you registered with the given email address and verified the registration via the confirmation mail.
            If you didn't receive a confirmation mail, please check your spam folder.`,
      validation: {
        email: "Please enter a valid e-mail address",
        confirmPassword: `The passwords don't match`
      },
      labels: {
        email: "e-mail"
      },
      buttons: {
        home: "Home",
        submit: "Reset password"
      }
    },
    ConfirmRegistration: {
      title: "e-mail verification",
      verifying: "We are verifying your e-mail address...",
      verified: `<b>Thank you for registering!</b><br>
      <p>Your e-mail has been verified - you can now log in to the actyvyst app!</p>
      <p><b>Best regards, actyvyst</b></p>`,
      verificationFailed: `<b>We could not verify your e-mail address</b>
      <p>This link is only valid for 24 h for security reasons - maybe you were too late?</p>
      <p>Please select "Send new verification link" to optain a new verification link.</p>
      <p><b>Best regards, your actyvyst team</b></p>`,
      generalError: `<b>Something went wrong...</b>
      <p>We could not complete your registration due to a technical error.</p>
      <p>Please try later or contact us via phone or e-mail:</p>
      +49 (0) 211 514 290 81<br><a href="mailto:contact@actyvyst.com">contact@actyvyst.com</a>`,
      resendSuccess: `<b>Please check your mail</b>
      <p>We sent you a new verification link - please click this link to finalize your registration.
      <p><b>Best regards, your actyvyst team</b><p/>`,
      buttons: {
        resendLink: "Send new verification link"
      }
    },
    ProviderRegistration: {
      title: "Become an actyvyst provider",
      introText: `<b>Great that you chose to become an actyvyst provider!</b><br>
      <p>You are offering tourist activities and want to be 
      visible in the <b style="color:#4196F6">actyvyst App</b> for your destination?
      Then you are at the right place!<br></p>
      Please tell us something about you and your company - we will activate your account for the
      actyvyst&nbsp;provider&nbsp;cockpit after a short validation.<br></p>
      <b>The actyvyst&nbsp;provider&nbsp;cockpit allows you to:</b>
      <p>Describe your activity and upload images to be displayed in the <b style="color:#4196F6">actyvyst&nbsp;app</b><br>
      Make your offer <b style="color:#4196F6">bookable online</b><br>`,

      successText: `<b>Thank you for registering!</b><br>
        <p>We will check your application as soon as possible and inform you once it is verified.</p>
        <p>If you have questions regarding your application, please don't hesitate to contact us:</p>
        <p>+49 (0) 211 514 290 81<br><a href="mailto:contact@actyvyst.com">contact@actyvyst.com</a></p>
        <p><b>Best regards, your actyvyst team</b><p/>`,
      errorText: `<b>Something went wrong...</b>
        <p>We could not complete your registration due to a technical error.</p>
        <p>Please try later or contact us via phone or e-mail:</p>
        +49 (0) 211 514 290 81<br><a href="mailto:contact@actyvyst.com">contact@actyvyst.com</a>`,

      applicationExistsText: `<b>Thank you for registering!</b>
        <p>We will check your application as soon as possible and inform you once it is verified.</p>
        <p>We recognized that you already have an actyvyst account with the e-mail address <b>{{email}}</b>
        - maybe because you registered already as a customer in the app.</p>
        <p>We will use your existing account to register you for the actyvyst&nbsp;provider&#8209;cockpit - 
        if you forgot your password for the exisiting account, you can change it here:</p>
        <a href="/forgotpassword">Forgot password?</a><br>`,
      userAlreadyRegisteredText: `<p>You are already registered as provider with the e-mail address you provided.</p >
        <p>To register as a new provider, please select <b>Register as new provider</b> and use a different e-mail address.</p>
        <p>To log in with your existing account, please select <b>Go to Cockpit</b> - in case you forgot your password, you can reset it there.</p>
      `,
      actions: {
        gotoCockpit: "Go to Cockpit",
        retryRegistration: "Register as new provider",
        home: "Home"
      },
      requiredFields: "Required fields",
      alreadyRegistered: `Already registered? <a href="{{cockpitUrl}}" target="_blank">Go to the provider cockpit</a>`,
      stepper: {
        intro: "About actyvyst",
        company: "Your company",
        contact: "Contact information"
      },
      buttons: {
        back: "Back",
        next: "Next",
        submit: "Submit",
        home: "Home"
      },
      labels: {
        provider: "Your company",
        country: "Country",
        street: "Street & house number",
        zip: "Zip code",
        city: "City",
        web: "Your website",
        description: "What are you offering?",
        firstName: "First name",
        lastName: "Last name",
        phone: "Phone",
        email: "E-mail",
        password: "New password",
        confirmPassword: "Repeat password"
      },
      validation: {
        required: "This field is required",
        max: "Please enter at most {{max}} characters",
        min: "Please enter at least {{min}} characters",
        email: "Please enter a valid e-mail address",
        zip: "Please enter a valid zip code",
        url: "Please enter a valid website url",
        phone: "Please enter a valid phone number",
        confirmPassword: `The passwords don't match`
      }
    },

    ActyvystDestinations: {
      teaser: "- your local activity guide",
      destinations: {
        sylt: "Sylt",
        texel: "Texel",
        allgaeu: "Allgäu",
        ruegen: "Rügen",
        gardasee: "Lake Garda",
        mallorca: "Majorca",
        korsika: "Corsica"
      },
      selectHeading: "Information for providers",
      selectIntro: `<p>You offer leisure activities and want to appear in the acytyvyst app?<br/>
      Here is a list of available regions including sign-up links.</p>
      <p>You miss your region? <a href="mailto:contact@actyvyst.com">Contact us</a></p>`,
      selectRegion: "Region",
      selectAppAvailable: "App available",
      selectSignUp: "Sign up as provider",
      selectAvailableYes: "Yes",
      selectAvailableShortly: "Shortly",
      selectAvailablePreparation: "In preparation",
      selectAvailableSignUpShortly: "Available soon",
      signUpNow: "Sign up now",
      sylt: {
        headline: "The compendium of leisure activities on the island of Sylt",
        teaser: `The <b>actyvyst Sylt</b> app is <b>the</b> free directory for leisure activities on the island of Sylt: 
          <p>All leisure activities are listed in an easy to use app for smart phones or tablets.
          If supported by the provider, all activities can be booked and paid directly in the app.</p>
          `,
        forProvidersTeaser: `You are offering tourist activities on the island of Sylt and want to be 
        visible in the actyvyst Sylt app for free?`,
        forProvidersQuestionTeaser:
          "You want to learn more about actyvyst Sylt? Check out our Q&A:",
        forProvidersLink: "Register as a provider",

        topics: {
          content: {
            title: "How do I get my content into the app?",
            body: `<p style="margin-top:0">If you register as a provider, you will get free access to our <b>provider cockpit</b> where you can manage your content.
              You can add up to five different activities to be shown in the app.
              Each activity can have its own textual descriptions, images and contact information.</p>
              <p>We will assist you with setting up your content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>`
          },
          whitelabel: {
            title: "How can I display my offers on my own website?",
            body: `<p style="margin-top:0">In our <b>Supplier Cockpit</b> you can specify whether your offers should also be made available as so-called whitelabel offers. </p><p>Here you can find <a href="/wldoc/sylt" target="_blank">detailed instructions on how to include the offers.</a></p>`
          },
          booking: {
            title: "How can I make my offers bookable?",
            body: `<p style="margin-top:0">If you choose to make your offer bookable, you can set up <b>bookable dates</b> with price tags
              for different categories (e.g. different prices for kids and adults). Tourists can then book and pay these offers directly in the app.
              The <b>actyvyst provider app</b> will inform you on all bookings in real time and allow you to manage your reservations.</p>
              <p>We will assist you with setting up bookable content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>
              `
          },
          payment: {
            title: "What does it cost?",
            body: `<p style="margin-top:0">Entering your content for the <b>actyvyst Sylt</b> app is <b>completely free</b>.<br>
              If you choose to set up bookable offers, <b>no fixed fee</b> will apply - we will just charge a percentage of your booking revenue.</p>
              `
          },
          marketing: {
            title: "How do travellers get the app?",
            body: `<p style="margin-top:0">We will promote the <b>actyvyst Sylt</b> app exactly when most tourists will start to plan their activities: 
              When they enter the island with the Sylt Shuttle.</p>
              <p>On the main arrival days we will hand out flyers in Niebüll to engage travellers to download the app and use their ride
              for getting to know the leisure offering on the island.</p>
              <p>We are running additional online-campaigns to reach travellers actively searching for activity categories like surfing, horse-riding or hiking.</p>`
          }
        },
        appStoreTeaser: `Try the <b>actyvyst Sylt</b> app yourself:`
      },
      ruegen: {
        headline: "The compendium of leisure activities on the island of Rügen",
        teaser: `The <b>actyvyst Rügen</b> app is <b>the</b> free directory for leisure activities on the island of Rügen: 
          <p>All leisure activities are listed in an easy to use app for smart phones or tablets.
          If supported by the provider, all activities can be booked and paid directly in the app.</p>
          `,
        forProvidersTeaser: `You are offering tourist activities on the island of Rügen and want to be 
        visible in the actyvyst Rügen app for free?`,
        forProvidersQuestionTeaser:
          "You want to learn more about actyvyst Rügen? Check out our Q&A:",
        forProvidersLink: "Register as a provider",

        topics: {
          content: {
            title: "How do I get my content into the app?",
            body: `<p style="margin-top:0">If you register as a provider, you will get free access to our <b>provider cockpit</b> where you can manage your content.
              You can add up to five different activities to be shown in the app.
              Each activity can have its own textual descriptions, images and contact information.</p>
              <p>We will assist you with setting up your content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>`
          },
          whitelabel: {
            title: "How can I display my offers on my own website?",
            body: `<p style="margin-top:0">In our <b>Supplier Cockpit</b> you can specify whether your offers should also be made available as so-called whitelabel offers. </p><p>Here you can find <a href="/wldoc/sylt" target="_blank">detailed instructions on how to include the offers.</a></p>`
          },
          booking: {
            title: "How can I make my offers bookable?",
            body: `<p style="margin-top:0">If you choose to make your offer bookable, you can set up <b>bookable dates</b> with price tags
              for different categories (e.g. different prices for kids and adults). Tourists can then book and pay these offers directly in the app.
              The <b>actyvyst provider app</b> will inform you on all bookings in real time and allow you to manage your reservations.</p>
              <p>We will assist you with setting up bookable content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>
              `
          },
          payment: {
            title: "What does it cost?",
            body: `<p style="margin-top:0">Entering your content for the <b>actyvyst Rügen</b> app is <b>completely free</b>.<br>
              If you choose to set up bookable offers, <b>no fixed fee</b> will apply - we will just charge a percentage of your booking revenue.</p>
              `
          },
          marketing: {
            title: "How do travellers get the app?",
            body: `<p style="margin-top:0">We will promote the <b>actyvyst Rügen</b> app exactly when most tourists will start to plan their activities: 
              When they enter the island.</p>
              <p>Our online campaigns reach travellers at destination, actively searching for activity categories like surfing, horse-riding or hiking.</p>
              <p>Additional on-site flyer and billboard campaigns support our brand visibility and extend our reach within the target group.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Rügen</b> will be live shortly!<br/>Meanwhile, check out our pilot app <b>actyvyst Sylt</b>:`
      },
      mallorca: {
        headline:
          "The compendium of leisure activities on the island of Mallorca",
        teaser: `The <b>actyvyst Mallorca</b> app is <b>the</b> free directory for leisure activities on the island of Mallorca: 
          <p>All leisure activities are listed in an easy to use app for smart phones or tablets.
          If supported by the provider, all activities can be booked and paid directly in the app.</p>
          `,
        forProvidersTeaser: `You are offering tourist activities on the island of Mallorca and want to be 
        visible in the actyvyst app for free?`,
        forProvidersQuestionTeaser:
          "You want to learn more about actyvyst Mallorca? Check out our Q&A:",
        forProvidersLink: "Register as a provider",

        topics: {
          content: {
            title: "How do I get my content into the app?",
            body: `<p style="margin-top:0">If you register as a provider, you will get free access to our <b>provider cockpit</b> where you can manage your content.
              You can add up to five different activities to be shown in the app.
              Each activity can have its own textual descriptions, images and contact information.</p>
              <p>We will assist you with setting up your content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>`
          },
          whitelabel: {
            title: "How can I display my offers on my own website?",
            body: `<p style="margin-top:0">In our <b>Supplier Cockpit</b> you can specify whether your offers should also be made available as so-called whitelabel offers. </p><p>Here you can find <a href="/wldoc/sylt" target="_blank">detailed instructions on how to include the offers.</a></p>`
          },
          booking: {
            title: "How can I make my offers bookable?",
            body: `<p style="margin-top:0">If you choose to make your offer bookable, you can set up <b>bookable dates</b> with price tags
              for different categories (e.g. different prices for kids and adults). Tourists can then book and pay these offers directly in the app.
              The <b>actyvyst provider app</b> will inform you on all bookings in real time and allow you to manage your reservations.</p>
              <p>We will assist you with setting up bookable content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>
              `
          },
          payment: {
            title: "What does it cost?",
            body: `<p style="margin-top:0">Entering your content for the <b>actyvyst Mallorca</b> app is <b>completely free</b>.<br>
              If you choose to set up bookable offers, <b>no fixed fee</b> will apply - we will just charge a percentage of your booking revenue.</p>
              `
          },
          marketing: {
            title: "How do travellers get the app?",
            body: `<p style="margin-top:0">We will promote the <b>actyvyst Mallorca</b> app exactly when most tourists will start to plan their activities: 
              When they enter the island.</p>
              <p>Our online campaigns reach travellers at destination, actively searching for activity categories like surfing, horse-riding or hiking.</p>
              <p>Additional on-site flyer and billboard campaigns support our brand visibility and extend our reach within the target group.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Mallorca</b> will be live shortly!<br/>Meanwhile, check out our pilot app <b>actyvyst Sylt</b>:`
      },
      allgaeu: {
        headline: "The compendium of leisure activities in the Allgäu",
        teaser: `The <b>actyvyst Allgäu</b> app is <b>the</b> free directory for leisure activities in the Allgäu: 
          <p>All leisure activities are listed in an easy to use app for smart phones or tablets.
          If supported by the provider, all activities can be booked and paid directly in the app.</p>
          `,
        forProvidersTeaser: `You are offering tourist activities in the Allgäu and want to be 
        visible in the actyvyst Allgäu app for free?`,
        forProvidersQuestionTeaser:
          "You want to learn more about actyvyst Allgäu? Check out our Q&A:",
        forProvidersLink: "Register as a provider",

        topics: {
          content: {
            title: "How do I get my content into the app?",
            body: `<p style="margin-top:0">If you register as a provider, you will get free access to our <b>provider cockpit</b> where you can manage your content.
              You can add up to five different activities to be shown in the app.
              Each activity can have its own textual descriptions, images and contact information.</p>
              <p>We will assist you with setting up your content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>`
          },
          booking: {
            title: "How can I make my offers bookable?",
            body: `<p style="margin-top:0">If you choose to make your offer bookable, you can set up <b>bookable dates</b> with price tags
              for different categories (e.g. different prices for kids and adults). Tourists can then book and pay these offers directly in the app.
              The <b>actyvyst provider app</b> will inform you on all bookings in real time and allow you to manage your reservations.</p>
              <p>We will assist you with setting up bookable content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>
              `
          },
          payment: {
            title: "What does it cost?",
            body: `<p style="margin-top:0">Entering your content for the <b>actyvyst Allgäu</b> app is <b>completely free</b>.<br>
              If you choose to set up bookable offers, <b>no fixed fee</b> will apply - we will just charge a percentage of your booking revenue.</p>
              `
          },
          marketing: {
            title: "How do travellers get the app?",
            body: `<p style="margin-top:0">We will promote the <b>actyvyst Allgäu</b> app exactly when most tourists will start to plan their activities: 
            When they enter the Allgäu.</p>
            <p>Our online campaigns reach travellers at destination, actively searching for activity categories like hiking, horse-riding or canyoning.</p>
            <p>Additional on-site flyer and billboard campaigns support our brand visibility and extend our reach within the target group.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Allgäu</b> will be live shortly!<br/>Meanwhile, check out our pilot app <b>actyvyst Sylt</b>:`
      },
      texel: {
        headline: "The compendium of leisure activities on the island of Texel",
        teaser: `The <b>actyvyst Texel</b> app is <b>the</b> free directory for leisure activities on the island of Texel: 
          <p>All leisure activities are listed in an easy to use app for smart phones or tablets.
          If supported by the provider, all activities can be booked and paid directly in the app.</p>
          `,
        forProvidersTeaser: `You are offering tourist activities on the island of Texel and want to be 
        visible in the actyvyst Texel app for free?`,
        forProvidersQuestionTeaser:
          "You want to learn more about actyvyst Texel? Check out our Q&A:",
        forProvidersLink: "Register as a provider",

        topics: {
          content: {
            title: "How do I get my content into the app?",
            body: `<p style="margin-top:0">If you register as a provider, you will get free access to our <b>provider cockpit</b> where you can manage your content.
              You can add up to five different activities to be shown in the app.
              Each activity can have its own textual descriptions, images and contact information.</p>
              <p>We will assist you with setting up your content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>`
          },
          booking: {
            title: "How can I make my offers bookable?",
            body: `<p style="margin-top:0">If you choose to make your offer bookable, you can set up <b>bookable dates</b> with price tags
              for different categories (e.g. different prices for kids and adults). Tourists can then book and pay these offers directly in the app.
              The <b>actyvyst provider app</b> will inform you on all bookings in real time and allow you to manage your reservations.</p>
              <p>We will assist you with setting up bookable content: <a href="mailto:contact@actyvyst.com">Just contact us!</a></p>
              `
          },
          payment: {
            title: "What does it cost?",
            body: `<p style="margin-top:0">Entering your content for the <b>actyvyst Texel</b> app is <b>completely free</b>.<br>
              If you choose to set up bookable offers, <b>no fixed fee</b> will apply - we will just charge a percentage of your booking revenue.</p>
              `
          },
          marketing: {
            title: "How do travellers get the app?",
            body: `<p style="margin-top:0">We will promote the <b>actyvyst Rügen</b> app exactly when most tourists will start to plan their activities: 
            When they enter the island.</p>
            <p>Our online campaigns reach travellers at destination, actively searching for activity categories like surfing, horse-riding or hiking.</p>
            <p>Additional on-site flyer and billboard campaigns support our brand visibility and extend our reach within the target group.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Texel</b> will be live shortly!<br/>Meanwhile, check out our pilot app <b>actyvyst Sylt</b>:`
      }
    },
    WhiteLabelHowTo: {
      Headline:
        "How to make your actyvyst offers available on your own website",
      Intro:
        "With the following instructions you can make targeted offers that you have published in the actyvyst apps bookable on your website",
      Step1Title: "Step 1",
      Step1Intro:
        "Copy and paste the following script tag inside the {`<head>`} element of your website:",
      Step1CodeBlock:
        "<script type='text/javascript'>\n   var h = document.querySelector('head');\n   var actyScript = document.createElement('script'); \n   let resourcePath = 'https://{{destination}}.actyvyst.com/wl/actylauncher.js?pid=<Your provider ID>&destination={{destination}}'; \n actyScript.src = resourcePath + '&v=' + Date.now(); \n  h.append(actyScript);\n</script>",
      Step1Explanation:
        "**Please note replace <your provider ID> with your correct ID!** You can copy and paste this in the profile of your actyvyst cockpit account at the marked position.",
      Step2Title: "Step 2",
      Step2Intro:
        "Place as many of the following elements as you like within the {`<body>`} tag of your website to present your offers in different ways:",
      Step2CodeBlockTitle: "actyvyst Button",
      Step2CodeBlock:
        "<div class='actyvystButton' data-buttonTitle='Horseriding for kids'></div>",
      Step2Explanation:
        "This element creates a button with a link to all the offers you have published on actyvyst. The text of the button is defined with the attribute `data-buttonTitle`. With another attribute (`data-deeplink`) you can define the link level:",
      Step2CodeBlockBTitle: "Offer detail page",
      Step2CodeBlockCTitle: "Offer dates page",
      Step2CodeBlockDTitle: "actyvyst Link",
      Step2CodeBlockB:
        "<div class='actyvystButton' \n   data-deeplink='deine-stunde-mit-dem-pony/?a_id=60d184f6421c448cec847196' \n   data-buttonTitle='Horseriding for kids'>\n</div>",
      Step2CodeBlockC:
        "<div class='actyvystButton' \n   data-deeplink='variants/61cc51303910c943509dc605' \n   data-buttonTitle='Horseriding for kids'>\n</div>",
      Step2CodeBlockD:
        "<a class='actyvystLink' data-deeplink='deine-stunde-mit-dem-pony/?a_id=60d184f6421c448cec8471963'>Horseriding for kids</a>",
      Step2ExplanationD:
        "This element creates a simple link (**`<a href...>`**) with which you can link directly to a page in the actyvyst Whitelabel booking section. Again, the link level is controlled by the **`data-deeplink`** attribute.",
      Step2ExplanationB:
        "You can copy the respective deep links from the actyvyst website where your offers can be found. The buttons need a CSS class called **`actyvystButton`**, but can also have several individual CSS classes, e.g. **`some other actyvystButton`**."
    }
  }
};

export default en;
