import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { actyBlue } from "../../style/colors";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

import Topics from "../ActyvystDestination/Topics";
import PDFTeaser from "./PDFTeaser";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { destinations } from "../ActyvystDestination/destinations";
import { useParams, useHistory } from "react-router-dom";
import config from "../../config";
import InlineFooter from "../../components/InlineFooter";
const { cockpitUrl } = config;

// const cockpitUrl = 'https://provider.actyvyst.de'

const destinationToCountry = {
  ruegen: "Deutschland",
  sylt: "Deutschland",
  mallorca: "Spanien"
};

const pdfAvailable = {
  ruegen: ["de"],
  sylt: ["de"],
  mallorca: ["de", "en"]
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingBottom: 60,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 80,
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    backgroundColor: "white",
    minHeight: "100%",
    width: "100%",
    height: "auto",
    minWidth: "100%"
  },
  mainGrid: {
    height: "auto",
    minHeight: "100%",
    width: "100%",
    minWidth: "100%",
    padding: 0,
    margin: 0
  },
  gridElement: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 0
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: 30
    }
  },

  markDown: {
    textAlign: "left"
  },
  markDownHeadline: {
    textAlign: "left",
    fontWeight: "bold"
    // fontSize: '1.2em'
  }
}));

const MarkdownStyle = styled.div`
  a {
    color: ${actyBlue};
  }
  p {
    margin: 0;
    paddingleft: 0;
  }
`;

const MarkdownHeadlineStyle = styled.div`
  a {
    color: ${actyBlue};
  }
`;

const ForProviders = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const { destination } = params;
  const country = destinationToCountry[destination] || "Deutschland";
  const registerLink = `/anbieter?country=${country}`;

  const history = useHistory();
  const theme = useTheme();
  const paddingSecondCol = useMediaQuery(theme.breakpoints.down("md")) ? 0 : 0;
  const buttonFontSize = useMediaQuery(theme.breakpoints.down("xs"))
    ? 12
    : undefined;

  const pdfLanguages = pdfAvailable[destination];
  let pdfLanguage = "de";
  if (pdfLanguages) {
    if (pdfLanguages.find((item) => item === i18n.language)) {
      pdfLanguage = i18n.language;
    }
  }
  const downloadLink = `/pdf/providers_get_started_${pdfLanguage}_${destination}.pdf`;

  return (
    <React.Fragment>
      <div className={classes.mainContainer}>
        <Grid container className={classes.gridElement}>
          <Grid item container xs={12} className={classes.gridElement}>
            <Grid
              item
              xs={12}
              md={4}
              container
              className={classes.gridElement}
              alignItems="center"
              justify="flex-start"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ width: "100%", fontSize: buttonFontSize }}
                onClick={() => history.push(registerLink)}
              >
                {t("ForProviders.registerButton")}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              container
              justify="flex-start"
              alignItems="center"
              className={classes.gridElement}
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: paddingSecondCol
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  color: actyBlue,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: actyBlue,
                  fontSize: buttonFontSize
                }}
                onClick={() => {
                  window.open(cockpitUrl);
                }}
              >
                {t("ForProviders.loginButton")}
              </Button>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
          <Grid item container xs={12} className={classes.gridElement}>
            <Grid
              item
              xs={12}
              container
              className={classes.gridElement}
              justify="flex-start"
            >
              <MarkdownHeadlineStyle className={classes.markDownHeadline}>
                {/* <ReactMarkdown
                                className={classes.markDownHeadline}

                            > */}
                <p>
                  {t("ForProviders.downloadPDFTitle") + " "}
                  <a
                    href={downloadLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    download="actyvyst_for_providers.pdf"
                  >
                    {t("ForProviders.downloadPDFLinkText")}
                  </a>
                </p>

                {/* </ReactMarkdown> */}
              </MarkdownHeadlineStyle>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              className={classes.gridElement}
              style={{ marginBottom: 10 }}
            >
              <PDFTeaser
                destination={destinations[destination]?.name}
                image={`/img/bg-${destination}.jpg`}
                subtitle={t("ForProviders.subtitlePDF")}
                // onClick={() => { window.open('/pdf/providers_get_started_de_Sylt.pdf') }}
                downloadPath={downloadLink}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              container
              justify="flex-start"
              className={classes.gridElement}
              style={{ paddingLeft: paddingSecondCol }}
            >
              <MarkdownStyle>
                <ReactMarkdown className={classes.markDown}>
                  {t("ForProviders.bulletPointsPDF")}
                </ReactMarkdown>
              </MarkdownStyle>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.gridElement}>
            <Grid item xs={12} justify="flex-start" container>
              <ReactMarkdown className={classes.markDownHeadline}>
                {t("ForProviders.titleQA")}
              </ReactMarkdown>
            </Grid>
            <Grid item xs={12} md={8}>
              <Topics destination={destination} />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <Grid item container xs={12} className={classes.gridElement}>
            <Grid item xs={12} justify="flex-start" container>
              <ReactMarkdown className={classes.markDownHeadline}>
                {t("ForProviders.titleContact", {
                  destination: destinations[destination]?.name
                })}
              </ReactMarkdown>
            </Grid>

            <Grid item xs={8} container justify="flex-start">
              <Typography style={{ textAlign: "left" }}>
                <MarkdownStyle>
                  <ReactMarkdown className={classes.markDown}>
                    {t(`ForProviders.contact.${destination}`)}
                  </ReactMarkdown>
                </MarkdownStyle>
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
      </div>
      <InlineFooter visible={true} showContact={false} />
    </React.Fragment>
  );
};

export default ForProviders;
