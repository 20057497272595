import React, { useState } from "react";
import ContentContainer from "../../components/ContentContainer";
import InlineFooter from "../../components/InlineFooter";
import { Grid, Paper } from "@material-ui/core";
import { useStyles } from "./styles";
import ResetPasswordFormController from "./FormController";
import ResetPasswordError from "./Error";
import ResetPasswordSuccess from "./Success";
import { useParams } from "react-router-dom";

const ResetPasswordFormModes = {
  FORM: 0,
  SUCCESS: 1,
  ERROR: 2,
};

const ResetPassword = (props) => {
  const { uniqueId } = useParams();
  const classes = useStyles();
  const [mode, setMode] = useState(ResetPasswordFormModes.FORM);

  const onSuccess = (values) => {
    setMode(ResetPasswordFormModes.SUCCESS);
  };
  const onError = (values, err) => {
    setMode(ResetPasswordFormModes.ERROR);
  };
  return (
    <React.Fragment>
      <ContentContainer>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          style={{ marginTop: 40 }}
        >
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Paper className={classes.paper} elevation={3}>
                {mode === ResetPasswordFormModes.FORM ? (
                  <ResetPasswordFormController
                    uniqueId={uniqueId}
                    onError={onError}
                    onSuccess={onSuccess}
                  />
                ) : mode === ResetPasswordFormModes.SUCCESS ? (
                  <ResetPasswordSuccess />
                ) : (
                  <ResetPasswordError />
                )}
              </Paper>
            </div>
          </Grid>
        </Grid>
      </ContentContainer>
      <InlineFooter visible={true} showContact={true} />
    </React.Fragment>
  );
};

export default ResetPassword;
