import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.5)"
    // backgroundColor: 'transparent'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  }
}));

const panelContent = [
  "content",
  "whitelabel",
  "booking",
  "payment",
  "marketing"
];

const Topics = ({ destination }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (item) => (event, isExpanded) => {
    setExpanded(isExpanded ? item : false);
  };
  const panels = panelContent.map((item, index) => {
    return (
      <ExpansionPanel
        key={item}
        expanded={expanded === item}
        onChange={handleChange(item)}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${item}-content`}
          id={`panel${item}-header`}
        >
          <Typography
            align="left"
            className={classes.heading}
            style={
              expanded === item ? { fontWeight: "bold", color: "#4196f6" } : {}
            }
          >
            {t(`ActyvystDestinations.${destination}.topics.${item}.title`)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography align="left">
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  `ActyvystDestinations.${destination}.topics.${item}.body`,
                  { interpolation: { escapeValue: false } }
                )
              }}
            />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });

  return <div className={classes.root}>{panels}</div>;
};

export default Topics;
