import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import NavBar from "./components/NavBar";

import Mobile from "./screens/MobileScreen";
import "./App.css";
import ActyvystGoScreen from "./screens/ActyvystGoScreen";
import Imprint from "./screens/Imprint";
import Contact from "./screens/Contact";
import Security from "./screens/Security";
import TrackingWrapper from "./components/TrackingWrapper";
import withRoot from "./components/withRoot";
import CookieConsent from "react-cookie-consent";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ProviderRegistration from "./screens/ProviderRegistration";
import ForProviders from "./screens/ForProviders";
import WlDoc from "./screens/WlDoc";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import ConfirmRegistration from "./screens/ConfirmRegistration";
import AppLanding from "./screens/AppLanding/index.js";
import qs from "qs";

const languages = ["de", "en"];

function App() {
  const [initialized, setInitialized] = useState(false);
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    console.log(query);
    if (
      query.language &&
      languages.find(
        (language) => query.language.toLocaleLowerCase() === language
      )
    ) {
      i18n.changeLanguage(query.language);
      localStorage.setItem("language", query.language);
    } else {
      const language = localStorage.getItem("language");
      if (language && languages.find((l) => language === l)) {
        i18n.changeLanguage(language);
      }
    }
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!initialized) return <React.Fragment />;

  return (
    <Router>
      <TrackingWrapper>
        <div className="App" style={{ overflow: "hidden" }}>
          <NavBar />

          <Switch>
            {/* <Route exact path="/" component={HomeScreen} /> */}
            <Route exact path="/" component={AppLanding} />
            <Route path="/sylt">
              <Redirect to="/destinations/sylt" />
            </Route>
            <Route path="/ruegen">
              <Redirect to="/destinations/ruegen" />
            </Route>
            <Route path="/allgaeu">
              <Redirect to="/destinations/allgaeu" />
            </Route>
            <Route path="/texel">
              <Redirect to="/destinations/texel" />
            </Route>
            <Route path="/mallorca">
              <Redirect to="/destinations/mallorca" />
            </Route>
            <Route path="/mobile" component={Mobile} />
            <Route path="/actyvyst-go" component={ActyvystGoScreen} />
            <Route path="/kontakt" component={Contact} />
            <Route path="/impressum" component={Imprint} />
            <Route path="/datenschutz" component={Security} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetpassword/:uniqueId" component={ResetPassword} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route path="/anbieter" component={ProviderRegistration} />
            <Route path="/anbieter/success" component={ProviderRegistration} />
            <Route path="/anbieter/error" component={ProviderRegistration} />
            <Route path="/destinations/:destination" component={ForProviders} />
            <Route path="/wldoc/:destination" component={WlDoc} />
            <Route path="/confirm/:id" component={ConfirmRegistration} />
          </Switch>
          <CookieConsent
            location="bottom"
            buttonText="Einverstanden"
            cookieName="CookieConsent"
            style={{
              backgroundColor: "rgba(23, 44, 61, 0.9)",
              borderTop: "1px solid",
              borderTopColor: theme.palette.secondary.main,
              flex: 1,
              flexDirection: "row",
              justifyContent: "center"
            }}
            buttonStyle={{
              backgroundColor: theme.palette.secondary.main,
              color: "white",
              borderRadius: 6,
              marginTop: 4
            }}
            // debug={true}
          >
            {t("actyvyst.de verwendet Cookies ")}
            <Link
              style={{ color: theme.palette.secondary.main }}
              to="/datenschutz"
            >
              {t("Mehr erfahren")}
            </Link>
          </CookieConsent>
        </div>
      </TrackingWrapper>
    </Router>
  );
}

export default withRoot(App);
