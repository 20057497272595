import React from "react";
import { actyBlue } from "../../style/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  actyvyst: {
    fontFamily: "Days One",
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
    },
  },
  destination: {
    fontFamily: "Days One",
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
    },

    color: actyBlue,
  },
  subtitle: {
    margin: 0,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      fontSize: 9,
      lineHeight: 1,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      lineHeight: 1.4,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
      lineHeight: 1.2,
    },
  },
  titleBox: {
    zIndex: 2,
    display: "block",
    position: "absolute",
    bottom: 10,
    left: 10,
    height: "30%",
    minHeight: 80,
    minWidth: 220,
    width: "40%",
    backgroundColor: "white",
    borderRadius: 6,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));

const PDFTeaser = ({
  destination = "Sylt",
  image = "/img/thumb-sylt.jpg",
  subtitle,
  onClick,
  downloadPath,
}) => {
  const classes = useStyles();
  return (
    // <Link to={downloadPath} target="_blank" download>
    <a
      href={downloadPath}
      target="_blank"
      rel="noopener noreferrer"
      download="actyvyst_for_providers.pdf"
    >
      <div
        style={{ position: "relative", cursor: "pointer" }}
        onClick={onClick || (() => {})}
      >
        <img
          alt={destination}
          src={image}
          style={{
            display: "block",
            width: "100%",
            height: "auto",
            maxWidth: "100%",
          }}
        />
        <div className={classes.titleBox}>
          <span className={classes.actyvyst}>actyvyst</span>
          <span className={classes.destination}>{` ${destination}`}</span>
          <p className={classes.subtitle}>{subtitle}</p>
        </div>
      </div>
    </a>
  );
};

export default PDFTeaser;
