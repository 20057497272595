import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useStyles } from "./styles";

const ForgotPasswordFormUI = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Form className={classes.form}>
      <Grid container justify="center">
        <Typography variant="h6">{t("ForgotPassword.title")}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography paragraph>{t("ForgotPassword.mainText")}</Typography>
        </Grid>

        <Grid item xs={12} sm={7} className={classes.fieldContainer}>
          <Field
            required
            component={TextField}
            name="email"
            label={t("ForgotPassword.labels.email")}
            variant="outlined"
            className={classes.field}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.fieldContainer}>
          <Button
            variant="contained"
            color="secondary"
            disabled={props.isSubmitting}
            onClick={() => {
              window.scrollTo(0, 0);
              props.submitForm();
            }}
            className={classes.button}
          >
            {t("ForgotPassword.buttons.submit")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ForgotPasswordFormUI;
