import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ResetPasswordFormUI from "./FormUI";
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const ResetPasswordFormController = (props) => {
  const { uniqueId } = props;
  const askForPin = uniqueId ? false : true;
  const { t } = useTranslation();
  const handleSubmit = (values, { setSubmitting }) => {
    const resetPassword = async () => {
      try {
        setSubmitting(true);
        if (askForPin) {
          await axios.post("/auth/resetpasswordbypin", {
            challengeType: "pin",
            pin: values.pin,
            email: values.email,
            newPassword: values.password,
          });
        } else {
          await axios.post("/auth/resetpassword", {
            challengeType: "uuid",
            challengeUuid: uniqueId,
            newPassword: values.password,
          });
        }
        setSubmitting(false);
        props.onSuccess();
      } catch (err) {
        setSubmitting(false);
        props.onError(err);
      }
    };
    resetPassword();
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        pin: "",
        email: "",
        password: "",
        confirmNewPassword: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        email: askForPin
          ? Yup.string()
              .max(50, t("generic.validation.max", { max: 50 }))
              .email(t("ResetPassword.validation.email"))
          : Yup.string(),
        pin: askForPin
          ? Yup.string()
              .required(t("generic.validation.required"))
              .test("zip", t("ResetPassword.validation.pin"), (val) => {
                if (!val) return false;
                return val.length === 4 && val.match(/^[0-9]*$/gm);
              })
          : Yup.string(),
        password: Yup.string()
          .required(t("generic.validation.required"))
          .min(5, t("generic.validation.min", { min: 5 })),
        confirmPassword: Yup.string()
          .required(t("generic.validation.required"))
          .test(
            "equalsPassword",
            t("ResetPassword.validation.confirmPassword"),
            function (val) {
              return val === this.parent.password;
            }
          ),
      })}
      validateOnBlur={true}
      validateOnChange={false}
      render={(formikProps) => {
        return <ResetPasswordFormUI askForPin={askForPin} {...formikProps} />;
      }}
    />
  );
};

export default ResetPasswordFormController;
