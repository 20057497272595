import defaultConfig from './config.default.js'
import devConfig from './config.dev.js'
import prodConfig from './config.prod.js'

let config = defaultConfig;
switch (process.env.NODE_ENV) {
    case 'development':
        config = { ...config, ...devConfig };
        break;
    case 'production':
        config = { ...config, ...prodConfig };
        break;
    default: break;
}

export default config;