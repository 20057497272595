import withRoot from "../components/withRoot";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InlineFooter from "../components/InlineFooter";
import Grid from "@material-ui/core/Grid";
import ContentContainer from "../components/ContentContainer";
import { useTranslation } from "react-i18next";
import { Typography, Link } from "@material-ui/core";

const styles = theme => ({
  root: {
    color: theme.palette.common.white
  },
  rightLink: {
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.light,

    verticalAlign: "center",
    marginTop: 10
  },
  icon: {
    marginTop: 10,
    marginLeft: 10,
    color: theme.palette.primary.light,
    opacity: 0.3,
    width: "3vwmin"
  }
});
function Imprint(props) {
  const { classes } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ContentContainer>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h4">{t("Impressum")}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          style={{ marginTop: 20, paddingBottom: 100 }}
        >
          <Grid item xs={12}>
            <Typography variant="body2" component="p">
              <b>actyvyst GmbH </b>
              <br />
              Gerresheimer Straße 153 <br />
              40233 Düsseldorf
              <br />
              {t("managing directors", {
                names: "Thomas Görldt, Ralf Siewert"
              })}
              <br />
              {t("commercial register", {
                entry: "HRB 86132"
              })}
              <br />
              {t("vat number", {
                entry: "DE324741447"
              })}
              <br />
              <br />
              <Link
                href={"mailto:contact@actyvyst.com"}
                className={classes.rightLink}
              >
                contact@actyvyst.com
              </Link>
              <br />
              Tel. +49(0)211/51429081
              <br />
              <br />
              {t("Inhaltliche Verantwortung")}
              <br />
              Ralf Siewert
              <br />
              Dorotheenstraße 39
              <br />
              53111 Bonn
              <br />
            </Typography>
          </Grid>
        </Grid>
      </ContentContainer>
      <InlineFooter visible={true} showContact={true} />
    </React.Fragment>
  );
}
export default withRoot(withStyles(styles)(Imprint));
