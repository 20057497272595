import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { useParams } from "react-router-dom";
import axios from "axios";
import ContentContainer from "../../components/ContentContainer";
import { ActyvystAPIError } from "../../modules/APIError";
import { errorCodes } from "../../constants/errors";

const VerificationStates = {
  VERIFYING: 0,
  VERIFICATION_FAILED: 1,
  VERIFIED: 2,
  GENERAL_ERROR: 3,
  RESEND_SUCCESS: 4,
};

const ConfirmRegistration = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [verificationState, setVerificationState] = useState(
    VerificationStates.VERIFYING
  );
  const [resending, setResending] = useState(false);
  const { id } = useParams();

  const resendLink = async () => {
    try {
      setResending(true);
      await axios.post(`/auth/resendchallenge/${id}`, {});
      setVerificationState(VerificationStates.RESEND_SUCCESS);
      setResending(false);
    } catch (err) {
      setVerificationState(VerificationStates.GENERAL_ERROR);
      setResending(false);
    }
  };

  useEffect(() => {
    const verify = async () => {
      try {
        await axios.get(`/auth/verify/${id}`);
        setVerificationState(VerificationStates.VERIFIED);
      } catch (err) {
        const apiError = ActyvystAPIError.createFromAxiosError(err);
        if (
          apiError instanceof ActyvystAPIError &&
          (apiError.code === errorCodes.auth.CHALLENGE_ID_EXPIRED ||
            apiError.code === errorCodes.auth.CHALLENGE_ID_NOT_FOUND)
        ) {
          setVerificationState(VerificationStates.VERIFICATION_FAILED);
        } else {
          setVerificationState(VerificationStates.GENERAL_ERROR);
        }
      }
    };
    setVerificationState(VerificationStates.VERIFYING);
    verify();
  }, [id]);

  let content;
  switch (verificationState) {
    case VerificationStates.VERIFYING:
      content = t("ConfirmRegistration.verifying", {
        interpolation: { escapeValue: false },
      });
      break;
    case VerificationStates.VERIFIED:
      content = t("ConfirmRegistration.verified", {
        interpolation: { escapeValue: false },
      });
      break;
    case VerificationStates.VERIFICATION_FAILED:
      content = t("ConfirmRegistration.verificationFailed", {
        interpolation: { escapeValue: false },
      });
      break;
    case VerificationStates.RESEND_SUCCESS:
      content = t("ConfirmRegistration.resendSuccess", {
        interpolation: { escapeValue: false },
      });
      break;
    case VerificationStates.GENERAL_ERROR:
    default:
      content = t("ConfirmRegistration.generalError", {
        interpolation: { escapeValue: false },
      });
  }

  return (
    <ContentContainer>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
        style={{ marginTop: 40 }}
      >
        <Grid item xs={12}>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Paper className={classes.paper} elevation={3}>
              <Grid container justify="center">
                <Typography variant="h6">
                  {t("ConfirmRegistration.title")}
                </Typography>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                    />
                  </Typography>
                </Grid>
                {verificationState ===
                VerificationStates.VERIFICATION_FAILED ? (
                  <Grid item xs={12} className={classes.fieldContainer}>
                    <Button
                      disabled={resending}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        resendLink();
                      }}
                      className={classes.button}
                    >
                      {t("ConfirmRegistration.buttons.resendLink")}
                    </Button>
                  </Grid>
                ) : undefined}
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default ConfirmRegistration;
