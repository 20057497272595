import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Typography, Link } from "@material-ui/core";
// npm install --save-dev @iconify/react @iconify/icons-fa-solid
import { InlineIcon } from "@iconify/react";

import phoneAlt from "@iconify/icons-fa-solid/phone-alt";
import envelopeIcon from "@iconify/icons-fa-solid/envelope";
import { useTranslation } from "react-i18next";
const styles = theme => ({
    icon: {
        marginTop: 10,

        color: theme.palette.primary.light
    },
    rightLink: {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        // marginLeft: theme.spacing(3),

        verticalAlign: "center",
        marginTop: 10
    },
    small: {
        fontSize: 12,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
        // marginLeft: theme.spacing(3),

        verticalAlign: "center",
        marginTop: 10
    }
});

function InlineFooter(props) {
    const { visible, classes, showContact } = props;
    const { t } = useTranslation();
    if (visible) {
        return (
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={3}
                    style={{ marginTop: 20 }}
                >
                    {showContact === true ? (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Typography
                                    // variant="body3"
                                    style={{
                                        color: "white",
                                        fontWeight: "bold",

                                        textAlign: "center"
                                        // marginTop: 20
                                    }}
                                    component="p"
                                >
                                    {t("Sie haben Fragen?")}
                                </Typography>
                                <InlineIcon
                                    icon={envelopeIcon}
                                    width="20"
                                    height="20"
                                    className={classes.icon}
                                />
                                <Typography
                                    // variant="body3"
                                    component="span"
                                    style={{
                                        color: "white",
                                        textAlign: "left",
                                        marginLeft: 10
                                    }}
                                >
                                    <Link
                                        href={"mailto:contact@actyvyst.com"}
                                        className={classes.rightLink}
                                    >
                                        contact@actyvyst.com
                                    </Link>
                                </Typography>
                                <br />
                                <InlineIcon
                                    icon={phoneAlt}
                                    width="20"
                                    height="20"
                                    className={classes.icon}
                                />
                                <Typography
                                    // variant="body3"
                                    component="span"
                                    style={{
                                        color: "white",
                                        textAlign: "left",
                                        marginLeft: 10
                                    }}
                                >
                                    +49 211 514 290 81
                                </Typography>
                            </Grid>

                            <Grid item xs={12}></Grid>
                        </React.Fragment>
                    ) : (
                        <React.Fragment />
                    )}
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 60 }}>
                    <NavLink to="/impressum" className={classes.small}>
                        {t("navigation.Impressum")}
                    </NavLink>
                    <br />
                    <NavLink to="/datenschutz" className={classes.small}>
                        {t("navigation.Datenschutz")}
                    </NavLink>
                    <br />
                    <p className={classes.small}>© 2019 actyvyst GmbH</p>
                </Grid>
            </Container>
        );
    } else {
        return <React.Fragment />;
    }
}

InlineFooter.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InlineFooter);
