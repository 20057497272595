import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import withRoot from "./withRoot";

const styles = (theme) => ({
  background: {
    // backgroundImage: `url(${backgroundImage})`,
    backgroundColor: theme.palette.common.white,
    // backgroundPosition: "-50vh -26vw",
    // background: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  root: {
    color: theme.palette.common.white,
  },
});

const ContentContainer = (props) => {
  const { classes } = props;
  return (
    <Container className={classes.background} maxWidth="xl">
      <Container style={{ margin: 0, padding: 0 }} maxWidth="lg">
        {props.children}
      </Container>
    </Container>
  );
};

export default withRoot(withStyles(styles)(ContentContainer));
