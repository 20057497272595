import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ResetPasswordError = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.fieldContainer}>
      <Grid>
        <Typography
          variant="body2"
          component="p"
          style={{ textAlign: "center" }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t("ResetPassword.errorText", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Button
          variant="contained"
          color="secondary"
          disabled={props.isSubmitting}
          onClick={() => {
            history.push("/");
          }}
          className={classes.button}
        >
          {t("ResetPassword.buttons.home")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordError;
