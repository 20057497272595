import withRoot from "../components/withRoot";
// --- Post bootstrap -----
import React from "react";

import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import { Typography, Link } from "@material-ui/core";

import InlineFooter from "../components/InlineFooter";
import ContentContainer from "../components/ContentContainer";
import { useTranslation } from "react-i18next";
const styles = theme => ({
    root: {
        color: theme.palette.common.white
    },
    rightLink: {
        fontSize: 16,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.light,

        verticalAlign: "center",
        marginTop: 10
    },
    icon: {
        marginTop: 10,
        marginLeft: 10,
        color: theme.palette.primary.light,
        opacity: 0.3,
        width: "3vwmin"
    }
});
function Contact(props) {
    const { classes } = props;
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <ContentContainer>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={3}

                >
                    <Grid item xs={12}>
                        <Typography variant="h4">{t("Kontakt")}</Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={3}
                    style={{ marginTop: 20, paddingBottom: 100 }}
                >
                    <Grid item xs={12}>
                        <Typography variant="body2" component="p">
                            {t("Kontakt Text")}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b>actyvyst GmbH </b>
                            <br />
                            Gerresheimer Straße 153 <br />
                            40233 Düsseldorf
                            <br />
                            <br />
                            <Link
                                href={"mailto:contact@actyvyst.com"}
                                className={classes.rightLink}
                            >
                                contact@actyvyst.com
                            </Link>
                            <br />
                            Tel. +49 (0) 211 514 290 81
                            <br />
                            <br />
                        </Typography>
                    </Grid>
                </Grid>
            </ContentContainer>
            <InlineFooter visible={true} showContact={false} />
        </React.Fragment>
    );
}
export default withRoot(withStyles(styles)(Contact));
