import { createMuiTheme } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const rawTheme = createMuiTheme({

  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#172C3D"
        }
      }
    },
    MuiMenuItem: {
      root: {
        backgroundColor: "#172C3D",
        "&:hover": {
          backgroundColor: "white"
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      },
      contained: {
        color: "#ffffff",
        backgroundColor: "#b0bec5",
        '&:hover': {
          backgroundColor: "#808e95",
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: "#b0bec5",
          },
        }
      }
    },
    MuiButtonGroup: {
      root: {
        backgroundColor: "#172C3D"
      },
      groupedOutlined: {
        textTransform: "none",
        color: "#ffffff",
        "&:hover": {
          color: "#4196F6"
        },
        fontFamily: "'Open Sans', sans-serif"
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiStepIcon: {
      root: {
        color: '#b0bec5',
        '&$completed': {
          color: "#b0bec5",
        },
        '&$active': {
          color: "#4196F6",
        },
      },
      active: {},
      completed: {},
    },
  },
  palette: {
    primary: {
      light: "#4196F6",
      main: "#172C3D",
      dark: "#172C3D"
    },
    secondary: {
      light: "#f2f2f2",
      main: "#4196F6",
      dark: "#0069c3"
    },
    // default: {
    //   light: '#e2f1f8',
    //   main: '#b0bec5',
    //   dark: '#808e95'
    // },
    // secondary: {
    //   light: '#fff5f8',
    //   main: '#ff3366',
    //   dark: '#e62958',
    // },
    warning: {
      main: "#ffc071",
      dark: "#ffb25e"
    },
    error: {
      xLight: red[50],
      main: red[500],
      dark: red[700]
    },
    success: {
      xLight: green[50],
      main: green[500],
      dark: green[700]
    },
    background: {
      default: "#172C3D",
      intro: "#172C3D"
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Days One', sans-serif"
  }
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: rawTheme.typography.fontFamilySecondary
  // textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background.intro,
      default: rawTheme.palette.common.white
      // placeholder: grey[200]
    }
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: "1.5em",
      paddingTop: 6
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: "6vmin",
      color: rawTheme.palette.primary.main,
      textAlign: "left",

      marginTop: 76
    },

    h5: {
      ...rawTheme.typography.h5,
      ...fontHeader,
      fontSize: "1.5em",
      textAlign: "center",

      fontWeight: rawTheme.typography.fontWeightLight,
      color: "white"
    },

    h6: {
      ...rawTheme.typography.h5,
      ...fontHeader,
      color: rawTheme.palette.primary.light,
      textAlign: "left",
      fontSize: "1.5em",
      marginBottom: 16
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 18
    },
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: 16
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 16,
      textAlign: "left",
      color: rawTheme.palette.primary.dark
    },
    body3: {
      ...rawTheme.typography.body1,
      fontSize: 16,
      textAlign: "left",
      color: rawTheme.palette.secondary.light,
      fontWeight: rawTheme.typography.fontWeightMedium
    }
  }
};

export default theme;
