import React, { useState, useEffect } from "react";
import ContentContainer from "../../components/ContentContainer";
import InlineFooter from "../../components/InlineFooter";
import { Grid, Paper } from "@material-ui/core";
import RegistrationFormController from "./FormController";
import RegistrationSuccess from "./Success";
import RegistrationError from "./Error";
import { useStyles } from "./styles";
import { useQuery } from "../../util/use-query";

const RegistrationFormModes = {
  FORM: 0,
  SUCCESS: 1,
  ERROR: 2,
};

const ProviderRegistration = (props) => {
  // const [mode, setMode] = useState(RegistrationFormModes.USER_EXISTS);
  // const [registrationError, setRegistrationError] = useState(new ActyvystAPIError('User already registered', 500, errorCodes.auth.USER_ALREADY_REGISTERED));
  // const [email, setEmail] = useState('isewat@gmail.com');

  const query = useQuery();

  const [mode, setMode] = useState(RegistrationFormModes.FORM);
  const [registrationError, setRegistrationError] = useState(null);
  const [providerApplication, setProviderApplication] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onSuccess = (values) => {
    setRegistrationError(null);
    setProviderApplication(values);
    setMode(RegistrationFormModes.SUCCESS);
  };
  const onError = (err) => {
    setProviderApplication(null);
    setRegistrationError(err);
    setMode(RegistrationFormModes.ERROR);
  };

  return (
    <React.Fragment>
      <ContentContainer>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          style={{ marginTop: 40 }}
        >
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Paper className={classes.paper} elevation={3}>
                {mode === RegistrationFormModes.FORM ? (
                  <RegistrationFormController
                    onSuccess={onSuccess}
                    onError={onError}
                    country={query.get("country")}
                  />
                ) : mode === RegistrationFormModes.SUCCESS ? (
                  <RegistrationSuccess data={providerApplication} />
                ) : (
                  <RegistrationError error={registrationError} />
                )}
              </Paper>
            </div>
          </Grid>
          {/* <Grid item xs={12}>
                        <Typography variant="caption">{query.get('country')}</Typography>
                    </Grid> */}
        </Grid>
      </ContentContainer>
      <InlineFooter visible={true} showContact={true} />
    </React.Fragment>
  );
};

export default ProviderRegistration;
