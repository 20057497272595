import React from "react";
import PropTypes from "prop-types";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from "./AppBar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { actyBlue } from "../style/colors";

import { useTranslation } from "react-i18next";
import "../css/Link.css";
import "../css/logoanim.css";
const styles = (theme) => ({
  title: {
    fontSize: 24,
    fontFamily: "'Days One', sans-serif",
  },
  titleSuffix: {
    fontSize: 24,
    fontFamily: "'Days One', sans-serif",
    color: "#4196F6",
    marginLeft: 5,
  },
  // placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: "space-between",
    height: 70,

    // backgroundImage: `url(${barIcon})`,
    // backgroundRepeat: "no-repeat",
    // backgroundSize: 40
  },
  left: {
    // flex: 1,
    backgroundColor: "white",
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  mainDiv: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  rightLink: {
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.light,
    marginLeft: theme.spacing(3),
    marginRight: 10,
    verticalAlign: "center",
    marginTop: 10,
  },

  linkSecondary: {
    color: theme.palette.primary.light,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    color: actyBlue,
    // flex: 1,
    // justifyContent: "flex-end"
  },
  mContainer: {
    paper: {
      backgroundColor: "white",
    },
  },
  menu: {
    // backgroundColor: theme.palette.primary.main
    backgroundColor: "white",
    "&$selected": {
      backgroundColor: theme.palette.action.selected,
    },
  },
  logo: {
    height: "30px",
  },
  logoHorizontallyCenter: {
    position: "absolute",
    left: "50%",
    top: "52%",
    transform: "translate(-50%, -50%)",
    opacity: 0,
    zIndex: 999,
  },
  languageSwitch: {
    groupedTextPrimary: { color: "white" },
    // position: "absolute",
    // top: 0,
    // right: 0,
    // marginRight: 10,
    // marginTop: 8
  },
  appBarColorDefault: {
    backgroundColor: "white",
  },
});

const navActiveStyle = {
  fontWeight: "bold",
  // backgroundColor: 'white',
  color: "#4196F6",
  fontSize: 16,
  marginTop: 10,
};

function NavBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { classes } = props;
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  console.log(currentLanguage);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    if (["de", "en"].find((l) => lng === l))
      localStorage.setItem("language", lng);
  };
  // const showLogo = props.location.pathname === "/" ? false : true;
  const theme = useTheme();
  //   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    // <div>
    <AppBar
      position="fixed"
      // color="inherit"
      // className={classes.toolbar}
      classes={{
        root: classes.toolbar,
        colorDefault: classes.appBarColorDefault,
      }}
    >
      <div className={classes.mainDiv}>
        <img
          alt="actyvyst Logo"
          // src={`/img/logo_actyvyst_${currentLanguage}.png`}
          src={`/img/logo_actyvyst_${i18n.language}.png`}
          style={{ height: 40, maxHeight: 40, cursor: "pointer" }}
          onClick={() => {
            history.push("/");
          }}
        />
        <div style={{ marginLeft: "auto" }}>
          <ButtonGroup
            size="small"
            variant="outlined"
            color="secondary"
            className={classes.languageSwitch}
            disableFocusRipple={true}
            disableRipple={true}
          >
            <Button
              onClick={() => changeLanguage("de")}
              style={
                currentLanguage === "de"
                  ? {
                      backgroundColor: "white",
                      color: theme.palette.primary.light,
                    }
                  : { backgroundColor: "white", color: "#a1b1b8" }
              }
            >
              de
            </Button>
            <Button
              onClick={() => changeLanguage("en")}
              style={
                currentLanguage === "en"
                  ? {
                      backgroundColor: "white",
                      color: theme.palette.primary.light,
                    }
                  : { backgroundColor: "white", color: "#a1b1b8" }
              }
            >
              en
            </Button>
          </ButtonGroup>

          <IconButton
            color="inherit"
            aria-label="Zeige Menü"
            aria-controls="simple-menu"
            aria-haspopup="true"
            className={classes.menuButton}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            // style={classes.mContainer}
          >
            <MenuItem className={classes.menu} onClick={handleClose}>
              <NavLink
                to="/"
                exact={true}
                className={classes.rightLink}
                activeStyle={navActiveStyle}
              >
                {t("navigation.Home")}
              </NavLink>
            </MenuItem>
            {/* <MenuItem className={classes.menu} onClick={handleClose}>
                            {" "}
                            <NavLink
                                to="/actyvyst-go"
                                exact={true}
                                className={classes.rightLink}
                                activeStyle={navActiveStyle}
                            >
                                {t("navigation.actyvyst GO")}
                            </NavLink>
                        </MenuItem> */}
            {/* <MenuItem className={classes.menu} onClick={handleClose}>
                            <NavLink
                                to="/destinations"
                                className={classes.rightLink}
                                activeStyle={navActiveStyle}
                            >
                                {t("navigation.forProviders")}
                            </NavLink>
                        </MenuItem> */}
            {/* <MenuItem className={classes.menu} onClick={handleClose}>
                            <NavLink
                                to="/mobile"
                                className={classes.rightLink}
                                activeStyle={navActiveStyle}
                            >
                                {t("navigation.Mobile")}
                            </NavLink>
                        </MenuItem> */}

            <MenuItem className={classes.menu} onClick={handleClose}>
              <NavLink
                to="/kontakt"
                className={classes.rightLink}
                activeStyle={navActiveStyle}
              >
                {t("navigation.Kontakt")}
              </NavLink>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </AppBar>
    // </div>
  );
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(NavBar));
