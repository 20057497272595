import React, { useState } from 'react';
import ContentContainer from "../../components/ContentContainer";
import InlineFooter from "../../components/InlineFooter";
import { Grid, Paper } from "@material-ui/core";
import { useStyles } from './styles';
import ForgotPasswordFormController from './FormController';
import ForgotPasswordError from './Error';
import ForgotPasswordSuccess from './Success';

const ForgotPasswordFormModes = {
    FORM: 0,
    SUCCESS: 1,
    ERROR: 2
}

const ForgotPassword = (props) => {
    const classes = useStyles();
    const [mode, setMode] = useState(ForgotPasswordFormModes.FORM);

    const onSuccess = (values) => {
        setMode(ForgotPasswordFormModes.SUCCESS);
    }
    const onError = (values, err) => {
        setMode(ForgotPasswordFormModes.ERROR)
    }
    return (
        <React.Fragment>
            <ContentContainer>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={3}
                    style={{ marginTop: 40 }}
                >
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <Paper className={classes.paper} elevation={3}>
                                {
                                    mode === ForgotPasswordFormModes.FORM ?
                                        <ForgotPasswordFormController
                                            onError={onError}
                                            onSuccess={onSuccess}
                                        /> :
                                        mode === ForgotPasswordFormModes.SUCCESS ?
                                            <ForgotPasswordSuccess /> :
                                            <ForgotPasswordError />
                                }
                            </Paper>
                        </div>
                    </Grid>
                </Grid>
            </ContentContainer>
            <InlineFooter visible={true} showContact={true} />
        </React.Fragment>
    )
}

export default ForgotPassword;