import withRoot from "../components/withRoot";
// --- Post bootstrap -----
import React from "react";

import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import { Typography, useMediaQuery } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
// npm install --save-dev @iconify/react @iconify/icons-iwwa
import { Icon } from "@iconify/react";
import filePdf from "@iconify/icons-iwwa/file-pdf";

import InlineFooter from "../components/InlineFooter";
import ContentContainer from "../components/ContentContainer";
// import laptopCode from "@iconify/icons-fa-solid/laptop-code";
import { useTranslation } from "react-i18next";
const screenshot = `${process.env.PUBLIC_URL}/img/app_screenshot.png`;
const screenshot2 = `${process.env.PUBLIC_URL}/img/app_screenshot_2.png`;
const screenshot3 = `${process.env.PUBLIC_URL}/img/app_screenshot_3.png`;
const screenshot4 = `${process.env.PUBLIC_URL}/img/app_screenshot_4.png`;
const screenshot5 = `${process.env.PUBLIC_URL}/img/app_screenshot_5.png`;

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  gridRow: {
    backgroundColor: "transparent",
  },
  actyScreenshot: {
    height: "auto",
    width: "5vw",

    verticalAlign: "top",
  },
  introCard: {
    borderRadius: 6,
    borderWidth: 1,
    backgroundColor: "rgba(255, 255, 255, 0.3)",
  },
  icon: {
    marginTop: 10,
    marginLeft: 10,
    color: theme.palette.primary.light,
    opacity: 0.3,
    width: "3vwmin",
  },
  gridListTitle: {
    color: theme.palette.primary.dark,
    fontSize: "50%",
    marginTop: 30,
  },
  gridListTitleBar: {
    background: "transparent",
    // 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  downloadLink: {
    color: theme.palette.primary.light,
    "&:hover": {
      color: "#172C3D !important",
      textDecoration: "underline",
    },
    fontWeight: "bold",
  },
});

function ActyvystGoScreen(props) {
  const { classes } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const appleBadge = `${process.env.PUBLIC_URL}/img/apple-badge_${i18n.language}.png`;
  const googleBadge = `${process.env.PUBLIC_URL}/img/google-play-badge_s_${i18n.language}.png`;
  const pdf = `${process.env.PUBLIC_URL}/pdf/actyvystgo-details-${i18n.language}.pdf`;

  let visibleCols = 1.7;
  const innerWidth = window.innerWidth;
  let screenshotWidth = innerWidth / 2;

  if (isXs) {
    screenshotWidth = innerWidth / 2;
    visibleCols = 1.6;
  } else if (isMobile) {
    screenshotWidth = innerWidth / 3;
    visibleCols = 2;
  } else if (isMedium) {
    screenshotWidth = innerWidth / 3.5;
    visibleCols = 2.7;
  } else {
    screenshotWidth = 1280 / 4;
    visibleCols = 3.3;
  }

  const imgStyle = { width: screenshotWidth };

  return (
    <React.Fragment>
      <ContentContainer>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h4">
              actyvyst <span style={{ color: "#4196f6" }}>GO</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          style={{ marginTop: 20 }}
        >
          <Grid item xs={12}>
            {/* <Typography>
              isXs: {isXs ? 'true' : 'false'}, isMobile: {isMobile ? 'true' : 'false'}, isMedium: {isMedium ? 'true' : 'false'}, isLarge:{isLarge ? 'true' : 'false'}
            </Typography> */}
            {/* <Typography>{window.innerWidth}</Typography> */}
            <Typography variant="body2" component="p">
              {t("actyvyst GO Intro")}
            </Typography>
            <br />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <GridList
            cellHeight="auto"
            style={{ flexWrap: "nowrap", paddingBottom: 20 }}
            cols={visibleCols}
          >
            {/* <GridList style={{ flexWrap: 'nowrap', paddingBottom: 20 }} cols={visibleCols}> */}
            <GridListTile>
              <img
                src={screenshot}
                alt="iPhoneXScreenshot"
                style={imgStyle}
                // style={{
                //   width: screenshotWidth

                // }}
              />
              <GridListTileBar
                title={t("actyvyst GO Screenshot 1 Title")}
                classes={{
                  root: classes.gridListTitleBar,
                  title: classes.gridListTitle,
                }}
              />
            </GridListTile>
            <GridListTile>
              <img
                src={screenshot2}
                alt="iPhoneXScreenshot"
                style={imgStyle}

                // style={{
                //   width: screenshotWidth

                // }}
              />
              <GridListTileBar
                title={t("actyvyst GO Screenshot 2 Title")}
                classes={{
                  root: classes.gridListTitleBar,
                  title: classes.gridListTitle,
                }}
              />
            </GridListTile>
            <GridListTile>
              <img
                src={screenshot3}
                alt="iPhoneXScreenshot"
                style={imgStyle}

                // style={{
                //   width: screenshotWidth

                // }}
              />
              <GridListTileBar
                title={t("actyvyst GO Screenshot 3 Title")}
                classes={{
                  root: classes.gridListTitleBar,
                  title: classes.gridListTitle,
                }}
              />
            </GridListTile>
            <GridListTile>
              <img
                src={screenshot4}
                alt="iPhoneXScreenshot"
                style={imgStyle}

                // style={{
                //   width: screenshotWidth

                // }}
              />
              <GridListTileBar
                title={t("actyvyst GO Screenshot 4 Title")}
                classes={{
                  root: classes.gridListTitleBar,
                  title: classes.gridListTitle,
                }}
              />
            </GridListTile>
            <GridListTile>
              <img
                src={screenshot5}
                alt="iPhoneXScreenshot"
                style={imgStyle}

                // style={{
                //   width: screenshotWidth
                // }}
              />
              <GridListTileBar
                title={t("actyvyst GO Screenshot 5 Title")}
                classes={{
                  root: classes.gridListTitleBar,
                  title: classes.gridListTitle,
                }}
              />
            </GridListTile>
          </GridList>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12} md={12}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              {t("Der digitale Freizeitguide")}
            </Typography>
            <Typography variant="body2">
              {t("actyvyst description 1")}
            </Typography>
            <br />
            <Typography variant="body2">
              {t("actyvyst description 2")}
            </Typography>
            <br />
            <Typography variant="body2">
              {t("actyvyst description 3")}
            </Typography>
            <br />
            <Typography variant="body2">
              <a
                href={pdf}
                target="_blank"
                rel="noopener noreferrer"
                download
                className={classes.downloadLink}
              >
                <Icon
                  icon={filePdf}
                  width="20"
                  height="20"
                  style={{ marginBottom: -4 }}
                />{" "}
                {t("actyvyst-go-details-pdf")}
              </a>
            </Typography>
            <br />
            <Typography variant="body2">
              {t("Jetzt testen auf Android oder iOS")}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 30 }}>
            <Box
              display="flex"
              flexWrap="nowrap"
              flexDirection="row"
              alignContent="flex-start"
              css={{ height: 50 }}
            >
              <Box css={{ maxWidth: "50%" }}>
                <a
                  href="https://apps.apple.com/us/app/actyvyst-sylt/id1465082431?l=de&ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleBadge}
                    alt={t("Jetzt im App Store laden")}
                    style={{
                      height: 50,
                      width: "auto",
                    }}
                  />
                </a>
              </Box>
              <Box css={{ maxWidth: "50%" }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.actyvystsylt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={googleBadge}
                    alt={t("Jetzt im Google Play Store laden")}
                    style={{
                      height: 50,
                      width: "auto",
                      marginLeft: 10,
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ContentContainer>
      <InlineFooter visible={true} showContact={true} />
    </React.Fragment>
  );
}
export default withRoot(withStyles(styles)(ActyvystGoScreen));
