import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from './styles';
import { useTranslation } from "react-i18next";

const IntroStep = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.fieldContainer}>

      <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ProviderRegistration.introText', { 'interpolation': { 'escapeValue': false } })
          }}
        />
      </Typography>
    </Grid>
  )
}

export default IntroStep;