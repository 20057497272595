import React from 'react';
import { Grid, Typography, Button } from "@material-ui/core";
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const ResetPasswordSuccess = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <React.Fragment>
            <Grid container className={classes.fieldContainer}>
                <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t('ResetPassword.successText', { 'interpolation': { 'escapeValue': false } })
                        }}
                    />
                </Typography>
            </Grid>
            <Grid container justify="center" >
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={props.isSubmitting}
                    onClick={() => { history.push('/') }}
                    className={classes.button}
                >
                    {t('ResetPassword.buttons.home')}
                </Button>
            </Grid>
        </React.Fragment>
    )
}

export default ResetPasswordSuccess;