import React from 'react';
import { Grid, Typography, Button } from "@material-ui/core";
import { useStyles } from './styles';
import { useTranslation } from "react-i18next";
import { errorCodes } from '../../constants/errors';
import { useHistory } from 'react-router-dom';


const RegistrationError = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const code = props.error ? props.error.code : null;

    const getErrorText = () => {
        switch (code) {
            case errorCodes.auth.EMAIL_ALREADY_REGISTERED_AS_PROVIDER:
                return 'ProviderRegistration.userAlreadyRegisteredText';
            case errorCodes.auth.EMAIL_ALREADY_EXISTS:
                return 'ProviderRegistration.applicationExistsText';
            default:
                return 'ProviderRegistration.errorText';
        }
    }
    const errorText = getErrorText();

    return (
        <Grid container className={classes.fieldContainer}>
            {/* <Grid item xs={12}>
                <Typography>{props.error.message}</Typography>
                <Typography>Code {props.error.code}</Typography>
            </Grid> */}
            <Grid>
                <Typography variant="body2" component="p" style={{ textAlign: 'center' }}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: t(errorText, { 'interpolation': { 'escapeValue': false } })
                        }}
                    />
                </Typography>
            </Grid>
            {
                code === errorCodes.auth.EMAIL_ALREADY_REGISTERED_AS_PROVIDER ?
                    <Grid container justify="flex-end" >
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={props.isSubmitting}
                            onClick={() => { window.open(`${process.env.REACT_APP_COCKPIT_HOST}`) }}
                            className={classes.button}
                        >
                            {t('ProviderRegistration.actions.gotoCockpit')}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={props.isSubmitting}
                            onClick={() => { window.location.reload(); }}
                            className={classes.button}
                        >
                            {t('ProviderRegistration.actions.retryRegistration')}
                        </Button>
                    </Grid>
                    : <Grid container justify="flex-end">
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={props.isSubmitting}
                            onClick={() => { history.push('/'); }}
                            className={classes.button}
                        >
                            {t('ProviderRegistration.actions.home')}
                        </Button>
                    </Grid>
            }
        </Grid>
    )
}

export default RegistrationError;