import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { actyBlue } from "../../style/colors";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useParams } from "react-router-dom";
import InlineFooter from "../../components/InlineFooter";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingBottom: 60,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 80,
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    backgroundColor: "white",
    minHeight: "100%",
    width: "100%",
    height: "auto",
    minWidth: "100%"
  },
  mainHeadline: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 24,
    color: actyBlue,
    fontWeight: theme.typography.fontWeightBold
  },
  stepHeadline: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: actyBlue,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(1)
  },
  stepContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  explanationBlock: {
    textAlign: "left"
  },
  markdownContainer: {
    textAlign: "left"
  }
}));

const WlDoc = () => {
  const classes = useStyles();
  const params = useParams();
  const { t } = useTranslation();
  const { destination } = params;
  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item container xs={12}>
          <Typography variant="h1" className={classes.mainHeadline}>
            {t("WhiteLabelHowTo.Headline")}
          </Typography>
          <p className={classes.explanationBlock}>
            {t("WhiteLabelHowTo.Intro")}
          </p>
        </Grid>
        <Grid item container className={classes.stepContainer} xs={12}>
          <Typography variant="h3" className={classes.stepHeadline}>
            {t("WhiteLabelHowTo.Step1Title")}
          </Typography>
          <Typography variant="body1" align="left"></Typography>
          <pre>
            <SyntaxHighlighter
              language="js"
              style={prism}
              wrapLongLines={true}
              PreTag="div"
              children={t("WhiteLabelHowTo.Step1CodeBlock", { destination })}
            />
          </pre>
          <ReactMarkdown
            children={t("WhiteLabelHowTo.Step1Explanation")}
            className={classes.markdownContainer}
          />
        </Grid>
        <Grid item container className={classes.stepContainer} xs={12}>
          <Typography variant="h3" className={classes.stepHeadline}>
            {t("WhiteLabelHowTo.Step2Title")}
          </Typography>
          <Typography variant="body1" align="left">
            {t("WhiteLabelHowTo.Step2Intro")}
          </Typography>
          <Typography variant="h3" className={classes.stepHeadline}>
            {t("WhiteLabelHowTo.Step2CodeBlockTitle")}
          </Typography>
          <pre>
            <SyntaxHighlighter
              language="js"
              style={prism}
              wrapLongLines={true}
              PreTag="div"
              children={t("WhiteLabelHowTo.Step2CodeBlock")}
            />
          </pre>

          <ReactMarkdown
            children={t("WhiteLabelHowTo.Step2Explanation")}
            className={classes.markdownContainer}
          />
          <Typography variant="h3" className={classes.stepHeadline}>
            {t("WhiteLabelHowTo.Step2CodeBlockBTitle")}
          </Typography>
          <pre>
            <SyntaxHighlighter
              language="js"
              style={prism}
              wrapLongLines={true}
              PreTag="div"
              children={t("WhiteLabelHowTo.Step2CodeBlockB")}
            />
          </pre>
          <Typography variant="h3" className={classes.stepHeadline}>
            {t("WhiteLabelHowTo.Step2CodeBlockCTitle")}
          </Typography>
          <pre>
            <SyntaxHighlighter
              language="js"
              style={prism}
              wrapLongLines={true}
              PreTag="div"
              children={t("WhiteLabelHowTo.Step2CodeBlockC")}
            />
          </pre>
          <ReactMarkdown
            children={t("WhiteLabelHowTo.Step2ExplanationB")}
            className={classes.markdownContainer}
          />
          <Typography variant="h3" className={classes.stepHeadline}>
            {t("WhiteLabelHowTo.Step2CodeBlockDTitle")}
          </Typography>

          <SyntaxHighlighter
            language="js"
            style={prism}
            PreTag="div"
            wrapLongLines={true}
            children={t("WhiteLabelHowTo.Step2CodeBlockD")}
          />

          <ReactMarkdown
            children={t("WhiteLabelHowTo.Step2ExplanationD")}
            className={classes.markdownContainer}
          />
        </Grid>
      </Grid>
      <InlineFooter visible={true} showContact={true} />
    </>
  );
};

export default WlDoc;
