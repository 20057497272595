import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
    },
    fieldContainer: {
        width: '100%',
        padding: 5
    },
    field: {
        width: '100%'
    },
    button: {
        minWidth: '30%',
        marginTop: 20,
        marginBottom: 20,
        marginRight: 5,
        marginLeft: 5
    },
    paper: {
        backgroundColor: 'white',
        width: '100%',
        minWidth: 360,
        maxWidth: 640,
        marginBottom: 20,
        marginTop: 20,
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export { useStyles };