import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useGlobalClasses from "../../style/global-classes";
import { isAndroid, isIOS } from "react-device-detect";
import { actyBlue, actyRed } from "../../style/colors";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  media: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 70,
      minHeight: 70,
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: 180,
      minHeight: 180,
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: 180,
      minHeight: 180,
    },
  },
  offerButton: {
    zIndex: 100,
    position: "absolute",
    left: 0,
    top: 0,
    borderRadius: 0,
    backgroundColor: actyRed,
    "&:hover": {
      backgroundColor: "#940044",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: actyRed,
      },
    },
  },
  cardTitle: {
    marginTop: -10,
    padding: 0,
    paddingTop: 0,
    // lineClamp: 1,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxLines: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.6em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8em",
    },
  },
  storeIcon: {
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: 32,
    },
    [theme.breakpoints.down("sm")]: {
      height: 32,
    },
    [theme.breakpoints.down("lg")]: {
      height: 38,
    },
    [theme.breakpoints.up("lg")]: {
      height: 38,
    },
  },
}));

const TeaserCard = ({
  image,
  destination,
  onWebLink,
  onAppstoreLink,
  onPlaystoreLink,
  onProviderLink,
  comingSoon,
}) => {
  const globalClasses = useGlobalClasses();
  const classes = useStyles();
  const { t } = useTranslation();

  const cardMedia = (
    <CardMedia
      component="img"
      alt={image.altText}
      className={classes.media}
      image={image.url}
      title={image.altText}
    />
  );

  return (
    <Card className={globalClasses.offerCard} style={{ borderRadius: 15 }}>
      {
        <CardActionArea
          onClick={() => {
            if (comingSoon !== true) {
              onWebLink();
            }
          }}
        >
          {cardMedia}
          <Button variant="contained" className={classes.offerButton}>
            {" "}
            {t("AppLanding.browseActivities")}
            <ArrowForwardIcon />
          </Button>
        </CardActionArea>
      }

      <CardContent className={globalClasses.cardContent}>
        <Typography className={classes.cardTitle} variant="h3">
          actyvyst <span style={{ color: actyBlue }}>{destination}</span>
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container>
          {comingSoon !== true ? (
            <React.Fragment>
              {isIOS || (!isIOS && !isAndroid) ? (
                <Grid item xs={4}>
                  <img
                    style={{ height: 36, cursor: "pointer" }}
                    src="/img/apple-badge_de.png"
                    alt="AppStore Link"
                    onClick={onAppstoreLink}
                  />
                </Grid>
              ) : undefined}
              {isAndroid || (!isIOS && !isAndroid) ? (
                <Grid item xs={4}>
                  <img
                    style={{ height: 36, cursor: "pointer" }}
                    src="/img/google-play-badge_s_de.png"
                    alt="AppStore Link"
                    onClick={onPlaystoreLink}
                  />
                </Grid>
              ) : undefined}
              {isIOS || isAndroid ? <Grid item xs={4}></Grid> : undefined}
            </React.Fragment>
          ) : (
            <Grid item xs={8}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2em",
                  color: actyBlue,
                }}
              >
                Coming soon!
              </Typography>
            </Grid>
          )}

          <Grid item xs={4}>
            <Button
              style={{ height: 36, fontSize: "0.6em" }}
              color="secondary"
              variant="outlined"
              onClick={onProviderLink}
            >
              {t("AppLanding.forProviders")}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default TeaserCard;
