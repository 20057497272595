import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import _ from "lodash";
import CompanyStep from "./CompanyStep";
import IntroStep from "./IntroStep";
import ContactStep from "./ContactStep";
import { useStyles } from "./styles";

const steps = [
  {
    label: "ProviderRegistration.stepper.intro",
    component: <IntroStep />,
    validation: [],
  },
  {
    label: "ProviderRegistration.stepper.company",
    component: <CompanyStep />,
    validation: [
      "provider.name",
      "provider.address.zip",
      "provider.address.street",
      "provider.address.city",
      "provider.web",
      "provider.description",
    ],
  },
  {
    label: "ProviderRegistration.stepper.contact",
    component: <ContactStep />,
    validation: [
      "contact.firstName",
      "contact.lastName",
      "contact.phone",
      "contact.email",
      "contact.password",
      "contact.confirmPassword",
    ],
  },
];

const RegistrationFormUI = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isNotMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const [activeStep, setActiveStep] = useState(0);

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Typography variant="h6">{t("ProviderRegistration.title")}</Typography>
      </Grid>
      <Form className={classes.form}>
        <Stepper
          activeStep={activeStep}
          orientation={isNotMobile ? "horizontal" : "vertical"}
        >
          {steps.map((step) => {
            return (
              <Step key={step.label}>
                <StepLabel>{t(step.label)}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {steps[activeStep].component}
        <Grid container justify="flex-end">
          {activeStep > 0 ? (
            <Button
              variant="contained"
              color="default"
              disabled={props.isSubmitting}
              onClick={() => {
                window.scrollTo(0, 0);
                setActiveStep(activeStep - 1);
              }}
              className={classes.button}
            >
              {t("ProviderRegistration.buttons.back")}
            </Button>
          ) : (
            <React.Fragment />
          )}
          {activeStep < steps.length - 1 ? (
            <Button
              variant="contained"
              color="secondary"
              disabled={props.isSubmitting}
              onClick={() => {
                const validate = async () => {
                  try {
                    let isValid = true;
                    props.validateForm().then((errors) => {
                      let newErrors = {};
                      let newTouched = {};
                      for (let i = activeStep; i < steps.length; i++) {
                        for (let field of steps[i].validation) {
                          const value = _.get(props.values, field);
                          if (value) {
                            const error = _.get(errors, field);
                            if (error) {
                              _.set(newErrors, field, error);
                              _.set(newTouched, field, true);
                            }
                          }
                        }
                      }
                      for (let field of steps[activeStep].validation) {
                        const value = _.get(errors, field);
                        if (value) {
                          _.set(newErrors, field, value);
                          _.set(newTouched, field, true);
                          isValid = false;
                        }
                      }
                      props.setErrors(newErrors);
                      props.setTouched(newTouched);
                      if (isValid) {
                        setActiveStep(activeStep + 1);
                      }
                      window.scrollTo(0, 0);
                    });
                  } catch (err) {
                    alert(err);
                  }
                };
                validate();
              }}
              className={classes.button}
            >
              {t("ProviderRegistration.buttons.next")}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              disabled={props.isSubmitting}
              onClick={() => {
                window.scrollTo(0, 0);
                props.submitForm();
              }}
              className={classes.button}
            >
              {t("ProviderRegistration.buttons.submit")}
            </Button>
          )}
        </Grid>
        <Grid container>
          {activeStep > 0 ? (
            <Typography variant="caption">
              * {t("ProviderRegistration.requiredFields")}
            </Typography>
          ) : (
            <React.Fragment>
              {/* <Typography variant="caption">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('ProviderRegistration.alreadyRegistered', { cockpitUrl: cockpitUrl, 'interpolation': { 'escapeValue': false } })
                                    }}
                                />
                            </Typography> */}
            </React.Fragment>
          )}
        </Grid>
      </Form>
    </React.Fragment>
  );
};

export default RegistrationFormUI;
