import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useStyles } from "./styles";

const ResetPasswordFormUI = (props) => {
  const { askForPin, isSubmitting, submitForm } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Form className={classes.form}>
      <Grid container justify="center">
        <Typography variant="h6">{t("ResetPassword.title")}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography paragraph>
            {t(askForPin ? "ResetPassword.pinText" : "ResetPassword.mainText")}
          </Typography>
        </Grid>

        {askForPin ? (
          <React.Fragment>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                required
                component={TextField}
                name="email"
                type="email"
                label={t("ResetPassword.labels.email")}
                variant="outlined"
                className={classes.field}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} className={classes.fieldContainer}>
              <Field
                required
                component={TextField}
                label={t("ResetPassword.labels.pin")}
                name="pin"
                variant="outlined"
                className={classes.field}
                inputProps={{ maxLength: 4 }}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        <Grid item xs={12} className={classes.fieldContainer}>
          <Field
            required
            component={TextField}
            name="password"
            type="password"
            label={t("ResetPassword.labels.password")}
            variant="outlined"
            className={classes.field}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>
        <Grid item xs={12} className={classes.fieldContainer}>
          <Field
            name="confirmPassword"
            required
            component={TextField}
            type="password"
            label={t("ResetPassword.labels.confirmPassword")}
            variant="outlined"
            className={classes.field}
            inputProps={{ maxLength: 30 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} className={classes.fieldContainer}>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              onClick={() => {
                window.scrollTo(0, 0);
                submitForm();
              }}
              className={classes.button}
            >
              {t("ResetPassword.buttons.submit")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ResetPasswordFormUI;
