import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const ForgotPasswordError = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.fieldContainer}>
      <Grid>
        <Typography
          variant="body2"
          component="p"
          style={{ textAlign: "center" }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t("ForgotPassword.errorText", {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordError;
