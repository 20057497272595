import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PhoneNumber from "awesome-phonenumber";
import { useTranslation } from "react-i18next";
import axios from "axios";
import RegistrationFormUI from "./FormUI";
import PropTypes from "prop-types";
import { ActyvystAPIError } from "../../modules/APIError";
import { Serializer, Deserializer } from "jsonapi-serializer";
const deserializer = new Deserializer({ keyForAttribute: "camelCase" });

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const urlRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;

const ProviderApplicationSerializer = new Serializer("provider", {
  attributes: ["provider", "contact"],
});

const RegistrationFormController = (props) => {
  const { t } = useTranslation();
  const handleSubmit = (values, { setSubmitting }) => {
    const register = async () => {
      try {
        const serializedData = ProviderApplicationSerializer.serialize(values);
        const response = await axios.post(
          "/auth/providerapplications",
          serializedData
        );
        const data = await deserializer.deserialize(response.data);
        props.onSuccess(data);
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
        const apiError = ActyvystAPIError.createFromAxiosError(err);
        props.onError(apiError);
      }
    };
    console.log(values);
    register();
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        provider: {
          name: "",
          address: {
            country: props.country || "Deutschland",
            street: "",
            zip: "",
            city: "",
          },
          web: "",
          description: "",
        },
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          // password: '',
          // confirmPassword: '',
        },
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        provider: Yup.object().shape({
          name: Yup.string()
            .required(t("ProviderRegistration.validation.required"))
            .max(50, t("ProviderRegistration.validation.max", { max: 50 })),
          address: Yup.object().shape({
            street: Yup.string()
              .required(t("ProviderRegistration.validation.required"))
              .max(50, t("ProviderRegistration.validation.max", { max: 50 })),
            zip: Yup.string()
              .required(t("ProviderRegistration.validation.required"))
              .test("zip", t("ProviderRegistration.validation.zip"), (val) => {
                if (!val) return false;
                return val.length === 5 && val.match(/^[0-9]*$/gm);
              }),
            city: Yup.string()
              .required(t("ProviderRegistration.validation.required"))
              .max(50, t("ProviderRegistration.validation.max", { max: 50 })),
          }),
          web: Yup.string()
            .max(50, t("ProviderRegistration.validation.max", { max: 50 }))
            .test("web", t("ProviderRegistration.validation.url"), (val) => {
              // return false;
              if (!val || val === "") {
                return true;
              }
              const result = val.match(urlRegex);
              return result ? true : false;
            }),
          description: Yup.string().max(
            200,
            t("ProviderRegistration.validation.max", { max: 200 })
          ),
        }),
        contact: Yup.object().shape({
          firstName: Yup.string()
            .required(t("ProviderRegistration.validation.required"))
            .max(50, t("ProviderRegistration.validation.max", { max: 50 })),
          lastName: Yup.string()
            .required(t("ProviderRegistration.validation.required"))
            .max(50, t("ProviderRegistration.validation.max", { max: 50 })),
          email: Yup.string()
            .max(50, t("ProviderRegistration.validation.max", { max: 50 }))
            .email(t("ProviderRegistration.validation.email")),
          phone: Yup.string()
            .max(50, t("ProviderRegistration.validation.max", { max: 50 }))
            .test(
              "isPhone",
              t("ProviderRegistration.validation.phone"),
              (val) => {
                if (!val || val === "") return true;
                const pn = new PhoneNumber(val);
                if (pn.isValid()) {
                  return true;
                } else {
                  const localPn = new PhoneNumber(val, "DE");
                  return localPn.isValid();
                }
              }
            ),
          // password: Yup.string().required(t('ProviderRegistration.validation.required')).min(5, t('ProviderRegistration.validation.min', { min: 5 })).max(30, t('ProviderRegistration.validation.max', { max: 30 })),
          // confirmPassword: Yup.string().required(t('ProviderRegistration.validation.required')).test('equalsPassword', t('ProviderRegistration.validation.confirmPassword'), function (val) {
          //     return val === this.parent.password
          // })
        }),
      })}
      validateOnBlur={true}
      validateOnChange={false}
      render={(formikProps) => {
        return <RegistrationFormUI {...formikProps} />;
      }}
    />
  );
};

RegistrationFormController.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default RegistrationFormController;
