import withRoot from "../components/withRoot";
// --- Post bootstrap -----
import React from "react";

import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Icon } from "@iconify/react";
import clipboardList from "@iconify/icons-fa-solid/clipboard-list";
import cloudUploadAlt from "@iconify/icons-fa-solid/cloud-upload-alt";
import slidersH from "@iconify/icons-fa-solid/sliders-h";
import InlineFooter from "../components/InlineFooter";
import laptopCode from "@iconify/icons-fa-solid/laptop-code";
import editIcon from "@iconify/icons-fa-solid/edit";
import ContentContainer from "../components/ContentContainer";
import { useTranslation } from "react-i18next";
const screenshot = `${process.env.PUBLIC_URL}/img/app_screenshot.png`;
const uparkScreenshot = `${process.env.PUBLIC_URL}/img/uparkScreenshot.png`;
const tsScreenshot = `${process.env.PUBLIC_URL}/img/tsScreenshot.png`;
const ebScreenshot = `${process.env.PUBLIC_URL}/img/screenshotEB.png`;

const styles = theme => ({
  root: {
    color: theme.palette.common.white
  },
  gridRow: {
    backgroundColor: "transparent"
  },
  actyScreenshot: {
    height: "auto",
    width: "25vw",

    verticalAlign: "top"
  },
  introCard: {
    borderRadius: 6,
    borderWidth: 1,
    backgroundColor: "rgba(255, 255, 255, 0.3)"
  },
  icon: {
    marginTop: 10,
    // marginLeft: 10,
    marginRight: 10,
    color: theme.palette.primary.main,
    opacity: 1,
    width: "3vwmin"
  },
  portfolioHeader: {
    marginBottom: 10
  }
});
function Mobile(props) {
  const { classes } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const appleBadge = `${process.env.PUBLIC_URL}/img/apple-badge_${i18n.language}.png`;
  const googleBadge = `${process.env.PUBLIC_URL}/img/google-play-badge_s_${i18n.language}.png`;
  return (
    <React.Fragment>
      <ContentContainer>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          maxWidth="lg"
        >
          <Grid item xs={12}>
            <Typography variant="h4">{t("Mobile Lösungen")}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
          style={{ marginTop: 20 }}
        >
          <Grid item xs={12}>
            <Typography variant="body2" component="p">
              {t("mobile solutions Intro 1")}
            </Typography>
            <Typography variant="body2" component="p">
              {t("mobile solutions Intro 2")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          spacing={3}
          style={{ marginTop: 20, backgroundColor: "transparent" }}
        >
          <Grid item xs={2} md={1} className={classes.gridRow}>
            <Icon
              icon={clipboardList}
              width="40"
              height="40"
              className={classes.icon}
            />
          </Grid>
          <Grid item xs={10} md={5} className={classes.gridRow}>
            <Typography variant="h6">
              {t("mobile solutions Strategy Headline")}
            </Typography>
            <Typography variant="body2" component="p">
              {t("mobile solutions Strategy Text")}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} className={classes.gridRow}>
            <Icon
              icon={editIcon}
              width="40"
              height="40"
              className={classes.icon}
            />
          </Grid>
          <Grid item xs={10} md={5} className={classes.gridRow}>
            <Typography variant="h6">
              {t("mobile solutions ux Headline")}
            </Typography>
            <Typography variant="body2" component="p">
              {t("mobile solutions ux Text")}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} className={classes.gridRow}>
            <Icon
              icon={slidersH}
              width="40"
              height="40"
              className={classes.icon}
            />
          </Grid>
          <Grid item xs={10} md={5} className={classes.gridRow}>
            <Typography variant="h6">
              {t("mobile solutions apis Headline")}
            </Typography>
            <Typography variant="body2" component="p">
              {t("mobile solutions apis Text")}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} className={classes.gridRow}>
            <Icon
              icon={laptopCode}
              width="40"
              height="40"
              className={classes.icon}
            />
          </Grid>
          <Grid item xs={10} md={5} className={classes.gridRow}>
            <Typography variant="h6">
              {t("mobile solutions dev Headline")}
            </Typography>
            <Typography variant="body2" component="p">
              {t("mobile solutions dev Text")}
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} className={classes.gridRow}>
            <Icon
              icon={cloudUploadAlt}
              width="40"
              height="40"
              className={classes.icon}
            />
          </Grid>
          <Grid item xs={10} md={5}>
            <Typography variant="h6">
              {t("mobile solutions maintenance Headline")}
            </Typography>
            <Typography variant="body2" component="p">
              {t("mobile solutions maintenance Text")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{ color: theme.palette.primary.main, marginTop: 30 }}
            >
              {t("Referenzen")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" component="p">
              {t("Referenzen Intro")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={screenshot}
              alt="iPhoneXScreenshot"
              className={classes.actyScreenshot}
              style={{
                width: "25vh"
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" className={classes.portfolioHeader}>
              {t("Ref actyvyst Sylt Headline")}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              dangerouslySetInnerHTML={{
                __html: t("Ref actyvyst Sylt Description")
              }}
            ></Typography>
            <Box
              display="flex"
              flexWrap="nowrap"
              flexDirection="row"
              alignContent="flex-start"
              css={{ height: 50 }}
            >
              <Box css={{ maxWidth: "50%" }}>
                <a
                  href="https://apps.apple.com/us/app/actyvyst-sylt/id1465082431?l=de&ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleBadge}
                    alt={t("Jetzt im App Store laden")}
                    style={{
                      height: 50,
                      width: "auto"
                    }}
                  />
                </a>
              </Box>
              <Box css={{ maxWidth: "50%" }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.actyvystsylt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={googleBadge}
                    alt={t("Jetzt im Google Play Store laden")}
                    style={{
                      height: 50,
                      width: "auto",
                      marginLeft: 10
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={uparkScreenshot}
              alt="uParkingScreenshot"
              className={classes.actyScreenshot}
              style={{
                width: "25vh"
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" className={classes.portfolioHeader}>
              {t("Ref uparking Headline")}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: t("Ref uparking Description")
              }}
            ></Typography>

            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              alignContent="flex-start"
              css={{ height: 100 }}
            >
              <Box css={{ maxWidth: 180, maxHeight: 100 }}>
                <a
                  href="https://apps.apple.com/us/app/uparking/id1137270856?l=de&ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleBadge}
                    alt={t("Jetzt im App Store laden")}
                    style={{
                      height: 50,
                      width: "auto"
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={tsScreenshot}
              alt="ScreenShot TeamShaker"
              className={classes.actyScreenshot}
              style={{
                width: "25vh"
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" className={classes.portfolioHeader}>
              {t("Ref teamshaker Headline")}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: t("Ref teamshaker Description")
              }}
            ></Typography>
            <br />
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              alignContent="flex-start"
              css={{ height: 100 }}
            >
              <Box css={{ maxWidth: 180, maxHeight: 100 }}>
                <a
                  href="https://apps.apple.com/us/app/teamshaker/id431165964?l=de&ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleBadge}
                    alt={t("Jetzt im App Store laden")}
                    style={{
                      height: 50,
                      width: "auto"
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <img
              src={ebScreenshot}
              alt="EscapeBox Screenshot"
              className={classes.actyScreenshot}
              style={{
                width: "25vh"
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" className={classes.portfolioHeader}>
              {t("Ref escapebox Headline")}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: t("Ref escapebox Description")
              }}
            ></Typography>
            <br />
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              alignContent="flex-start"
              css={{ height: 100 }}
            >
              <Box css={{ maxWidth: 180, maxHeight: 100 }}>
                <a
                  href="https://apps.apple.com/us/app/escapebox/id1479852620?l=de&ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleBadge}
                    alt={t("Jetzt im App Store laden")}
                    style={{
                      height: 50,
                      width: "auto"
                    }}
                  />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ContentContainer>
      <InlineFooter visible={true} showContact={true} />
    </React.Fragment>
  );
}
export default withRoot(withStyles(styles)(Mobile));
