const de = {
  translation: {
    "Mobile Lösungen": "Mobile Lösungen",
    "agencyTeaser Line1": "Full Service Agentur",
    "agencyTeaser Line2": "für Apps und mobile Websites.",
    "actyvyst GO": "actyvyst GO",
    "actyvystTeaser Line1": "Der digitale Freizeitguide.",
    "actyvystTeaser Line2": "Jetzt live für Sylt!",
    "HomeBanner Agency Teaser Line1":
      "Sie suchen einen zuverlässigen Dienstleister, der Ihnen effektive und schnelle Softwareentwicklung bietet?",
    "Home Banner actyvyst Go Teaser Line 1":
      "Sie sind Anbieter von Freizeitaktivitäten und möchten mehr über actyvyst GO erfahren?",
    "Home Banner actyvyst Go Teaser Line 2":
      "Sie arbeiten für eine Tourismus-Marketing-Organisation und sind an actyvyst GO für Ihre Destination interessiert?",
    "Nehmen Sie Kontakt mit uns auf!": "Nehmen Sie Kontakt mit uns auf!",
    navigation: {
      Home: "Home",
      "actyvyst GO": "actyvyst GO",
      Mobile: "Mobile Lösungen",
      Sylt: "actyvyst Sylt",
      forProviders: "Für Anbieter",
      Kontakt: "Kontakt",
      Impressum: "Impressum",
      Datenschutz: "Datenschutz"
    },
    // "navigation.Home": "Home",
    // "navigation.actyvyst GO": "actyvyst GO",

    // "navigation.Kontakt": "Kontakt",
    // "navigation.Impressum": "Impressum",
    // "navigation.Datenschutz": "Datenschutz",
    "Sie haben Fragen?": "Sie haben Fragen?",
    "actyvyst GO Intro":
      "Regionale Apps für regionale Anbieter: actyvyst GO ist unser Produkt für den mobilen Vertrieb von Urlaubsaktivitäten.",
    "actyvyst GO Screenshot 1 Title": "Aktivitätenliste",
    "actyvyst GO Screenshot 2 Title": "Aktuelle Nachrichten",
    "actyvyst GO Screenshot 3 Title": "Gutscheinansicht",
    "actyvyst GO Screenshot 4 Title": "Destinations-Quiz",
    "actyvyst GO Screenshot 5 Title": "Registrierung und Login",
    "Der digitale Freizeitguide": "Der digitale Freizeitguide",
    "actyvyst description 1":
      "In der actyvyst GO App finden Reisende das gesamte Freizeitangebot am Urlaubsort - egal, ob es sich dabei um einen Surfkurs, einen Reitausflug oder einen Theaterbesuch handelt.",
    "actyvyst description 2":
      "Jede Destination bekommt ihre eigene App, die wir direkt im Urlaubsort online wie offline bewerben - denn hier entscheiden die meisten Reisenden, was sie in den nächsten Tagen unternehmen wollen.",
    "actyvyst description 3":
      "Viele Aktivitäten lassen sich direkt in der actyvyst GO App buchen und nach Bestätigung durch den Veranstalter auch gleich online bezahlen.",
    "actyvyst description 4":
      "Im Sommer 2019 ist actyvyst GO auf Sylt erfolgreich gestartet.",
    "Jetzt testen auf Android oder iOS": "Jetzt testen auf Android oder iOS:",
    "Jetzt im App Store laden": "Jetzt im App Store laden",
    "Jetzt im Google Play Store laden": "Jetzt im Google Play Store laden",
    "mobile solutions Intro 1":
      "Wir planen, entwickeln und betreiben Apps und mobile Websites individuell nach Ihren Wünschen.",
    "mobile solutions Intro 2":
      "Unser erfahrenes Team betreut Sie in allen Projektphasen von der Idee bis zum Launch und kümmert sich um Betrieb und Weiterentwicklung Ihrer Plattform.",
    "mobile solutions Strategy Headline": "Strategie & Planung",
    "mobile solutions Strategy Text":
      "Ob fertiges Pflichtenheft oder vage Idee: Wir entwickeln gemeinsam mit Ihnen das Featureset für Ihr Produkt und evaluieren die technische Machbarkeit.",
    "mobile solutions ux Headline": "UX & UI",
    "mobile solutions ux Text":
      "Mit Wireframes und Prototypen visualieren wir frühzeitig Erscheinungsbild und Nutzerführung Ihres Produkts.",
    "mobile solutions apis Headline": "Schnittstellen & Daten",
    "mobile solutions apis Text":
      "In den seltensten Fällen steht eine App für sich allein. Wir sorgen für einen reibungslosen Zugriff auf Ihre Datenquellen.",
    "mobile solutions dev Headline": "Entwicklung & Launch",
    "mobile solutions dev Text":
      "Wir entwickeln in kurzen Zyklen und stimmen Zwischenergebnisse regelmäßig mit Ihnen ab. So können wir gemeinsam schnell reagieren, wenn sich Anforderungen ändern oder sich neue Prioritäten ergeben.",
    "mobile solutions maintenance Headline": "Betrieb & Support",
    "mobile solutions maintenance Text":
      "Wir betreiben und monitoren Ihre App und sorgen für regelmäßige Updates.",
    Referenzen: "Referenzen",
    "Referenzen Intro":
      "Machen Sie sich selbst ein Bild von unserer Arbeit – unsere bisherigen Projekte:",
    "Ref actyvyst Sylt Headline":
      "actyvyst Sylt - dein digitaler Freizeitguide",
    "Ref actyvyst Sylt Description":
      "<b>actyvyst Sylt</b> bietet einen umfassenden und kostenlosen Überblick über fast alle Aktivitäten, die man auf Sylt unternehmen kann: Wassersport, Naturerlebnisse, kulturelle Highlights, Wellness-Oasen, exklusive Boutiquen und die vielfältige Gastronomie machen hier jeden Urlaub einzigartig. <br /><br />Detaillierte Informationen zu Lage und Erreichbarkeit der Anbieter      erleichtern die Kontaktaufnahme. <br />Für viele Aktivitäten sind exklusive actyvyst Gutscheine verfügbar, die beim jeweiligen Anbieter vor Ort einlgelöst werden können. <br /><br />Die App ist für iOS und Android verfügbar: <br /><br />",
    "Ref uparking Headline": "UParking",
    "Ref uparking Description":
      "<b>UParking</b> ist ein kleiner Helfer im Alltag: mit dieser App lässt sich der letzte Parkplatz spielend leicht merken und wiederfinden. <br />Die Position des geparkten Fahrzeugs kann auch als Maps-Link weitergegeben werden. <br />Die App ist für iOS verfügbar: <br /> <br />",
    "Ref teamshaker Headline": "TeamShaker",
    "Ref teamshaker Description":
      "Mit <b>TeamShaker</b> können 2 oder mehr Teams aus einem oder mehreren Pools von beliebig vielen Teammitgliedern zusammengestellt werden - <br />und das mit einfachem Schütteln des iPhones. Die Teammitglieder können mit Stärkeeigenschaften angelegt werden, die dann bei der Zusammenstellung der Teams berücksichtigt werden, so dass ausgeglichene Teams entstehen. <br />Ideal für Sportmannschaften, Teamarbeit in der Schule etc.<br />Die App ist für iOS verfügbar: <br /> <br />",
    "Ref escapebox Headline": "Escape Box",
    "Ref escapebox Description":
      "Die App fungiert als Spielleiter zu einem Escape Room Spiel, das als interaktives Lernspiel von der Düsseldorfer Agentur jaeger + haeckerhase GmbH konzipiert und produziert wurde. <br />Die <b>Escape Box</b> ist dabei der „Taktgeber“ dieses Spiels – Timer und multimediales Hilfsmittel zur Lösung der Aufgaben in einem.",

    Kontakt: "Kontakt",
    "Kontakt Text":
      "Nehmen Sie Kontakt mit uns auf, wir freuen uns auf Ihre Nachricht!",
    Impressum: "Impressum",
    "managing directors": "Geschäftsführer: {{names}}",
    "commercial register":
      "Handelsregister beim Amtsgericht Düsseldorf Registernummer: {{entry}}",
    "vat number": "Umsatzsteuer-ID gemäß §27 a Umsatzsteuergesetz: {{entry}}",
    "Inhaltliche Verantwortung": "Inhaltliche Verantwortung: ",
    "actyvyst.de verwendet Cookies": "actyvyst.de verwendet Cookies",
    "Mehr erfahren": "Mehr erfahren",
    "actyvyst-go-details-pdf":
      "Detaillierte Informationen zu actyvyst GO (PDF)",

    countries: {
      Deutschland: "Deutschland",
      Frankreich: "Frankreich",
      Italien: "Italien",
      Niederlande: "Niederlande",
      Spanien: "Spanien"
    },

    generic: {
      validation: {
        required: "Dieses Feld ist erforderlich",
        max: "Bitte geben Sie maximal {{max}} Zeichen ein",
        min: "Bitte geben Sie mindestens {{min}} Zeichen ein"
      }
    },
    ForProviders: {
      registerButton: "Registrierung für Anbieter",
      loginButton: "Anmeldung für registrierte Anbieter",
      downloadPDFTitle: `Zusammenfassung  für Anbieter von Freizeitangeboten als`,
      downloadPDFLinkText: `PDF-Download`,
      subtitlePDF: `Zusammenfassung für Anbieter von Aktivitäten`,
      bulletPointsPDF: `Kurz und knapp: Um was geht es?  
Was bringt mir das als Anbieter von Aktivitäten?  
Wie kann ich mir die App vorstellen?  
Wie ist die Bedienung für Anbieter?   
Ich möchte mitmachen. Was muss ich machen?  `,
      titleQA: "Antworten auf häufige Fragen",
      titleContact: "actyvyst Anbieter-Support {{destination}}:",
      contact: {
        sylt: `Ralf Siewert  
                [+49 (0)160 921 801 31](tel:+4916092180131)  
                [ralf.siewert(at)actyvyst.com](mailto:ralf.siewert@actyvyst.com)  `,
        ruegen: `Ralf Siewert  
                [+49 (0)160 921 801 31](tel:+4916092180131)  
                [ralf.siewert(at)actyvyst.com](mailto:ralf.siewert@actyvyst.com)  `,
        mallorca: `Elena Kempf  
                [+34 639 878 090](tel:+34639878090)  
                [elena.kempf(at)actyvyst.com](mailto:elena.kempf@actyvyst.com)  `
      }
    },
    AppLanding: {
      teaser:
        "Die actyvyst Apps bieten Dir einen tollen Überblick über alle Aktivitäten, die Du an Deinem Urlaubsort unternehmen kannst.",
      downloadNow: "Jetzt herunterladen und ausprobieren:",
      forProviders: "Für Anbieter",
      browseActivities: "Zu den Aktivitäten",
      testimonials: [
        {
          text: "Bei actyvyst gibt es so tolle Angebote, nach denen hätte ich nie gesucht – nicht mal gedacht, dass es sowas überhaupt gibt",
          image: "/img/testimonial_OliP.png",
          name: "Oli.P"
        },
        {
          text: "Ich war schon oft auf Rügen. Aber bei actyvyst hab ich ein paar tolle neue Dinge gefunden für meine Rasselbande.",
          image: "/img/testimonial_InaUndAni.jpg",
          name: "Ina G."
        },
        {
          text: "Apps sind ja manchmal kompliziert. Aber mit actyvyst komm ich super klar.",
          image: "/img/testimonial_Ulla.png",
          name: "Ulla K."
        },
        {
          text: "Als Anbieter komme ich in Kontakt mit Leuten, die mich sonst nie gefunden hätten.",
          image: "/img/testimonial_Leo.png",
          name: "Leo K."
        }
      ]
    },
    ResetPassword: {
      title: "Passwort zurücksetzen",
      mainText: `Bitte geben Sie Ihr neues Passwort ein und klicken Sie auf "Neues Passwort verwenden". 
      Danach können Sie sich mit Ihrem neuen Passwort anmelden.`,
      pinText: `Bitte geben Sie Ihre E-Mail-Adresse sowie den 4-stelligen Sicherheitscode ein, den wir Ihnen per Mail geschickt haben.
      Anschließend geben Sie Ihr neues Passwort ein und klicken auf "Neues Passwort verwenden". 
      Danach können Sie sich mit Ihrem neuen Passwort anmelden.`,
      successText:
        "Ihr Passwort wurde erfolgreich geändert, Sie können sich nun mit Ihrem neuen Passwort einloggen.",
      errorText: `Beim Ändern Ihres Passwortes ist ein Fehler aufgetreten.`,
      errorTextTimeout: `Leider ist der Zeitraum zum Ändern Ihres Passwortes bereits abgelaufen. 
      Wenn Sie möchten, können Sie die Passwortänderung erneut beantragen, indem Sie auf "Passwort ändern" klicken.`,
      validation: {
        confirmPassword: "Die Passwörter stimmen nicht überein",
        pin: "Bitte geben Sie einen 4-stelligen Zahlencode ein"
      },
      labels: {
        email: "E-Mail",
        pin: "4-stelliger Code",
        password: "Neues Passwort",
        confirmPassword: "Wiederholung Passwort"
      },
      buttons: {
        home: "Zur Homepage",
        submit: "Neues Passwort verwenden"
      }
    },
    ForgotPassword: {
      title: "Passwort vergessen?",
      mainText: `Bitte geben Sie Ihre E-Mail Adresse ein und wählen anschließend "Passwort zurücksetzen".
      Sie erhalten dann von uns per E-Mail einen Link zum Zurücksetzen Ihres Passwortes.
      `,
      successText: `Der Link zum Zurücksetzen Ihres Passwortes wurde an die angegebene E-Mail-Adresse verschickt.`,
      errorText: `Ihr Passwort kann nicht zurückgesetzt werden. Bitte prüfen Sie, ob Sie sich mit der angegebenen E-Mail-Adresse registriert haben und die 
            Registrierung über die Bestätigungsmail bestätigt haben. Prüfen Sie bitte auch, ob sich die Bestätigungsmail in Ihrem Spam-Folder befindet.`,
      validation: {
        email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        confirmPassword: "Die Passwörter stimmen nicht überein"
      },
      labels: {
        email: "E-Mail"
      },
      buttons: {
        home: "Zur Homepage",
        submit: "Passwort zurücksetzen"
      }
    },
    ConfirmRegistration: {
      title: "Verifizierung E-Mail",
      verifying: "E-Mail Adresse wird verifiziert...",
      // verified: `<b>Vielen Dank für Ihre Anmeldung!</b>
      // <p>Wir haben Ihre E-Mail Adresse erfolgreich verifiziert - sobald wir Sie als Anbieter freigeschaltet haben, können Sie
      // sich mit Ihrer E-Mail Adresse und Ihrem Passwort im actyvyst Anbieter Cockpit einloggen.</p>
      // <p>Informationen zum Einloggen erhalten Sie in einer separaten Mail zur Anbieterfreischaltung.</p>
      // <p><b>Viel Erfolg mit actyvyst Sylt wünscht Ihr actyvyst Team!</b></p>`,
      verified: `<b>Vielen Dank für Deine Anmeldung!</b>
      <p>Wir haben Deine E-Mail Adresse erfolgreich verifiziert!
      <p><b>Viel Spaß mit der actyvyst App wünscht Dein actyvyst Team!</b></p>`,
      verificationFailed: `<b>Leider konnten wir Deine E-Mail Adresse nicht verifizieren.</b>
      <p>Dieser Link ist aus Sicherheitsgründen nur 24 Stunden lang gültig, möglicherweise hast Du diese Frist überschritten.</p>
      <p>Bitte wähle "Neuen Bestätigungs-Link senden", um Dir einen neuen Bestätigungs-Link zusenden zu lassen.</p>
      <p><b>Viele Grüße, Dein actyvyst Team</b></p>`,
      generalError: `<b>Leider ist ein technischer Fehler aufgetreten</b>
      <p>Bitte versuche es später noch einmal oder kontaktiere uns per Telefon oder E-Mail:</p>
      Tel. +49 (0) 211 514 290 81<br><a href="mailto:contact@actyvyst.com">contact@actyvyst.com</a>
      `,
      resendSuccess: `<b>Bitte prüfe Dein E-Mail Postfach</b>
      <p>Wir haben Dir einen neuen Bestätigungslink an Deine hinterlegte E-Mail Adresse geschickt.
      Bitte rufe den Link auf, um Deine Registrierung abzuschließen.</p>
      <p><b>Viele Grüße, Dein actyvyst Team</b></p>`,
      buttons: {
        resendLink: "Neuen Bestätigungs-Link senden"
      }
    },
    ProviderRegistration: {
      title: "actyvyst Anbieter werden",
      introText: `<b>Schön, dass Sie Ihr Angebot mit actyvyst präsentieren möchten!</b><br>
      <p>Sie bieten touristische Aktivitäten an und möchten kostenfrei in der <b style="color:#4196F6">actyvyst&nbsp;App</b> für Ihre Region erscheinen?
      Dann sind Sie hier richtig!<br></p>
      <p>Geben Sie uns im Folgenden einige kurze Informationen über sich und Ihr Unternehmen -
      wir werden Sie nach kurzer Prüfung für das actyvyst&nbsp;Anbieter&#8209;Cockpit freischalten.<br></p>
      <b>Mit dem actyvyst Anbieter-Cockpit können Sie:</b><br>
      <p>Ihr Angebot in der <b style="color:#4196F6">actyvyst App</b> beschreiben und Bilder hochladen.<br>
      Ihr Angebot <b style="color:#4196F6">online buchbar</b> machen.<br>
      Ihr individuelles <b style="color:#4196F6">Corona-Hygienekonzept</b> erläutern.<br></p>
      `,
      successText: `<b>Vielen Dank für Ihre Registrierung!</b><br>
      <p>Wir werden Ihre Anmeldung umgehend prüfen und Sie für das <b style="color:#4196F6">actyvyst&nbsp;Anbieter&#8209;Cockpit</b> freischalten.
      Sobald Sie die Freischaltung erhalten haben, können Sie sich mit Ihren Zugangsdaten beim actyvyst&nbsp;Anbieter&#8209;Cockpit anmelden</p>
      <p>Wenn Sie Fragen zum Stand Ihrer Registrierung haben, kontaktieren Sie uns gerne:</p>
      Tel. +49 (0) 211 514 290 81<br><a href="mailto:contact@actyvyst.com">contact@actyvyst.com</a>
      <p>Viel Erfolg, Ihr actyvyst&#8209;Team</p>`,
      errorText: `<b>Leider ist bei der Registrierung ein Fehler aufgetreten!</b>
      <p>Ihre Registrierung konnte leider wegen eines technischen Fehlers nicht abgeschlossen werden.</p>
      <p>Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns per Telefon oder E-Mail:</p>
      Tel. +49 (0) 211 514 290 81<br><a href="mailto:contact@actyvyst.com">contact@actyvyst.com</a>
      `,
      applicationExistsText: `<b>Vielen Dank für Ihre Registrierung!</b>
      <p>Wir haben festgestellt, dass Sie sich schon einmal unter der E-Mail-Adresse <b>{{email}}</b> registriert haben.</p>
      <p>Sie werden automatisch benachrichtigt, sobald wir Ihre Registrierung geprüft haben.</p>
      <p>Wenn Sie Fragen zum Stand Ihrer Registrierung haben, kontaktieren Sie uns gerne:</p>
      Tel. +49 (0) 211 514 290 81<br><a href="mailto:contact@actyvyst.com">contact@actyvyst.com</a>
      `,
      userAlreadyRegisteredText: `<p>Sie sind mit der angegebenen E-Mail-Adresse bereits als Anbieter bei actyvyst registriert.</p >
        <p>Um einen weiteren Anbieter zu registrieren, klicken Sie auf <b>Als neuer Anbieter registrieren</b> und verwenden Sie bitte bei der Registrierung eine andere E-Mail-Adresse.</p>
        <p>Um sich für den existierenden Anbieter anzumelden, klicken Sie bitte <b>Zum Cockpit</b> - sollten Sie Ihr Passwort vergessen haben, können Sie es dort auch zurücksetzen.</p>
      `,
      actions: {
        gotoCockpit: "Zum Cockpit",
        retryRegistration: "Als neuer Anbieter registrieren",
        home: "Zur Homepage"
      },
      requiredFields: "Pflichtfelder",
      alreadyRegistered: `Sie sind bereits registriert? <a href="{{cockpitUrl}}" target="_blank">Hier geht's zum Anbieter-Cockpit</a>`,
      stepper: {
        intro: "Über actyvyst",
        company: "Ihr Unternehmen",
        contact: "Ihre Kontaktdaten"
      },
      buttons: {
        back: "Zurück",
        next: "Weiter",
        submit: "Abschicken",
        home: "Zur Homepage"
      },
      labels: {
        provider: "Ihr Unternehmen",
        country: "Land",
        street: "Straße und Hausnummer",
        zip: "PLZ",
        city: "Stadt",
        web: "Ihre Website",
        description: "Was bieten Sie an?",
        firstName: "Vorname",
        lastName: "Nachname",
        phone: "Telefon",
        email: "E-Mail",
        password: "Gewünschtes Passwort",
        confirmPassword: "Wiederholung Passwort"
      },
      validation: {
        required: "Dieses Feld ist erforderlich",
        max: "Bitte geben Sie maximal {{max}} Zeichen ein",
        min: "Bitte geben Sie mindestens {{min}} Zeichen ein",
        email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        zip: "Bitte geben Sie eine gültige Postleitzahl ein",
        url: "Bitte geben Sie eine gültige Website ein",
        phone: "Bitte geben Sie eine gültige Telefonnummer ein",
        confirmPassword: "Die Passwörter stimmen nicht überein"
      }
    },
    ActyvystDestinations: {
      teaser: "- dein digitaler Freizeit-Guide",
      destinations: {
        sylt: "Sylt",
        texel: "Texel",
        allgaeu: "Allgäu",
        ruegen: "Rügen",
        gardasee: "Gardasee",
        mallorca: "Mallorca",
        korsika: "Korsika"
      },
      selectHeading: "Informationen für Anbieter",
      selectIntro: `<p>Sie sind Anbieter von Freizeitaktivitäten und möchten in der actyvyst App erscheinen oder Ihre Inhalte bearbeiten?
      Hier finden Sie eine Liste der verfügbaren Regionen inklusive Registrierungslinks.</p>
      <p>Ihre Region ist nicht dabei? <a href="mailto:contact@actyvyst.com">Kontaktieren&nbsp;Sie&nbsp;uns</a></p>
      `,
      selectRegion: "Region",
      selectAppAvailable: "App verfügbar",
      selectSignUp: "Registrierung als Anbieter",
      selectAvailableYes: "Ja",
      selectAvailableShortly: "In Kürze",
      selectAvailableSignUpShortly: "In Kürze verfügbar",
      selectAvailablePreparation: "In Vorbereitung",
      signUpNow: "Jetzt registrieren",
      sylt: {
        headline: "Das Kompendium für Freizeitaktivitäten auf Sylt",
        teaser: `Die <b>actyvyst Sylt</b> App bietet dem Urlauber <b>die</b> kostenlose Referenz für seine Freizeitgestaltung am Urlaubsort: 
      <p>Von der Aalkutter-Fahrt bis zum Zoo in Tinnum sind alle Freizeitmöglichkeiten in einer einfach zu bedienenden App 
      für Smartphones und Tablets zu finden und - je nach Verfügbarkeit - auch direkt online buchbar.</p>`,
        forProvidersTeaser:
          "Sie bieten Freizeitaktivitäten auf Sylt an und möchten kostenfrei in der actyvyst Sylt App erscheinen?",
        forProvidersQuestionTeaser:
          "Sie möchten mehr über actyvyst Sylt erfahren? Hier finden Sie Antworten auf die wichtigsten Fragen:",
        forProvidersLink: "Hier geht's direkt zur Anbieterregistrierung",

        topics: {
          content: {
            title: "Wie kommen meine Inhalte in die App?",
            body: `<p style="margin-top:0">Wenn Sie sich als Anbieter registrieren, erhalten Sie Zugang zu unserem <b>Anbieter-Cockpit</b>, in dem Sie Ihre Inhalte komfortabel verwalten können.
          Sie können bis zu fünf verschiedene Aktivitäten anlegen und diese mit Texten, Bildern und Kontaktinformationen beschreiben.</p>
          <p>Wir unterstützen Sie gerne beim Anlegen Ihrer Inhalte: Auf Wunsch helfen wir Ihnen beim Einstellen Ihres Angebots.</p>`
          },
          whitelabel: {
            title:
              "Wie kann ich meine Angebote auf meiner eigenen Website anzeigen?",
            body: `<p style="margin-top:0">In unserem <b>Anbieter-Cockpit</b> können Sie festlegen, ob Ihre Angebote auch als sogenannte Whitelabel-Angebote verfügbar gemacht werden sollen. </p><p>Hier finden Sie <a href="/wldoc/sylt" target="_blank"> eine detaillierte Anleitung zum Einbinden der Angebote</a></p>`
          },
          booking: {
            title: "Wie kann ich mein Angebot buchbar machen?",
            body: `<p style="margin-top:0">Wenn Sie Ihr Angebot buchbar machen möchten, können Sie für jede Aktivität eine beliebige Anzahl <b>buchbarer Termine</b> erstellen
          und diese mit Preisen hinterlegen. Urlauber können diese Termine dann direkt in der App buchen und bezahlen.
          Mit der <b>actyvyst Anbieter App</b> werden Sie in Echtzeit über alle Buchungen informiert und behalten so jederzeit den Überblick über Ihre Reservierungen.</p>
          <p>Wir beraten Sie gerne bei der Planung für Ihre Online-Buchbarkeit: <a href="mailto:contact@actyvyst.com">Kontaktieren Sie uns</a></p>
          `
          },
          payment: {
            title: "Was kostet mich die Teilnahme?",
            body: `<p style="margin-top:0">Das Einstellen von Inhalten in die <b>actyvyst Sylt</b> App ist für Anbieter <b>komplett kostenfrei</b>.<br>
          Wenn Sie Ihr Angebot online buchbar machen möchten, fallen auch hierfür <b>keine Fixkosten</b> an - wir finanzieren uns lediglich durch eine
          anteilige Buchungsprovision.</p>`
          },
          marketing: {
            title: "Wie wird die App beworben?",
            body: `<p style="margin-top:0">Wir bewerben die <b>actyvyst Sylt</b> App genau dann, wenn sie für Urlauber interessant wird: 
          Zum Zeitpunkt der Anreise mit dem Autozug.</p>
          <p>An den Hauptanreisetagen verteilen wir in Niebüll Flyer, die den Reisenden zum Download animieren, so dass er die Überfahrt dazu nutzen kann,
          sich auf komfortable Art und Weise mit dem vielfältigen Freizeitangebot auf Sylt vertraut zu machen.</p>
          <p>Flankiert wird diese Maßnahme von diversen Online-Kampagnen, die den Urlauber erreichen, wenn er z.B. auf Google nach bestimmten
          Aktivitäten wie Surfen, Reiten oder Wattwandern sucht.</p>`
          }
        },
        appStoreTeaser: `Überzeugen Sie sich selbst von <b>actyvyst Sylt</b>:`
      },
      ruegen: {
        headline: "Das Kompendium für Freizeitaktivitäten auf Rügen",
        teaser: `Die <b>actyvyst Rügen</b> App bietet dem Urlauber <b>die</b> kostenlose Referenz für seine Freizeitgestaltung am Urlaubsort: 
      <p>Vom Abenteuer-Golf in Göhren bis zum Zoo in Stralsund sind alle Freizeitmöglichkeiten in einer einfach zu bedienenden App 
      für Smartphones und Tablets zu finden und - je nach Verfügbarkeit - auch direkt online buchbar.</p>`,
        forProvidersTeaser:
          "Sie bieten Freizeitaktivitäten auf Rügen an und möchten kostenfrei in der actyvyst Rügen App erscheinen?",
        forProvidersQuestionTeaser:
          "Sie möchten mehr über actyvyst Rügen erfahren? Hier finden Sie Antworten auf die wichtigsten Fragen:",
        forProvidersLink: "Hier geht's direkt zur Anbieterregistrierung",

        topics: {
          content: {
            title: "Wie kommen meine Inhalte in die App?",
            body: `<p style="margin-top:0">Wenn Sie sich als Anbieter registrieren, erhalten Sie Zugang zu unserem <b>Anbieter-Cockpit</b>, in dem Sie Ihre Inhalte komfortabel verwalten können.
          Sie können bis zu fünf verschiedene Aktivitäten anlegen und diese mit Texten, Bildern und Kontaktinformationen beschreiben.</p>
          <p>Wir unterstützen Sie gerne beim Anlegen Ihrer Inhalte: Auf Wunsch helfen wir Ihnen beim Einstellen Ihres Angebots.</p>`
          },
          whitelabel: {
            title:
              "Wie kann ich meine Angebote auf meiner eigenen Website anzeigen?",
            body: `<p style="margin-top:0">In unserem <b>Anbieter-Cockpit</b> können Sie festlegen, ob Ihre Angebote auch als sogenannte Whitelabel-Angebote verfügbar gemacht werden sollen. </p><p>Hier finden Sie <a href="/wldoc/ruegen" target="_blank"> eine detaillierte Anleitung zum Einbinden der Angebote</a></p>`
          },
          booking: {
            title: "Wie kann ich mein Angebot buchbar machen?",
            body: `<p style="margin-top:0">Wenn Sie Ihr Angebot buchbar machen möchten, können Sie für jede Aktivität eine beliebige Anzahl <b>buchbarer Termine</b> erstellen
          und diese mit Preisen hinterlegen. Urlauber können diese Termine dann direkt in der App buchen und bezahlen.
          Mit der <b>actyvyst Anbieter App</b> werden Sie in Echtzeit über alle Buchungen informiert und behalten so jederzeit den Überblick über Ihre Reservierungen.</p>
          <p>Wir beraten Sie gerne bei der Planung für Ihre Online-Buchbarkeit: <a href="mailto:contact@actyvyst.com">Kontaktieren Sie uns</a></p>
          `
          },
          payment: {
            title: "Was kostet mich die Teilnahme?",
            body: `<p style="margin-top:0">Das Einstellen von Inhalten in die <b>actyvyst Rügen</b> App ist für Anbieter <b>komplett kostenfrei</b>.<br>
          Wenn Sie Ihr Angebot online buchbar machen möchten, fallen auch hierfür <b>keine Fixkosten</b> an - wir finanzieren uns lediglich durch eine
          anteilige Buchungsprovision.</p>`
          },
          marketing: {
            title: "Wie wird die App beworben?",
            body: `<p style="margin-top:0">Wir bewerben die <b>actyvyst Rügen</b> App genau dann, wenn sie für Urlauber interessant wird: 
          Zum Zeitpunkt der Ankunft auf der Insel.</p>
          <p>Unsere Onlinekampagnen werden ausgespielt, wenn der Urlauber sich auf Rügen oder im direkten Umfeld befindet.
          Der Urlauber wird direkt erreicht, sobald er auf Google z.B. nach Reiten, Surfen oder Aktivitäten für Kinder sucht.
          </p>
          <p>Flankiert werden diese Maßnahmen von diversen Flyer-Kampagnen und Plakataktionen.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Rügen</b> ist in Kürze online!<br/> Machen Sie sich vorab bereits ein Bild  
        und testen Sie die <b>actyvyst Sylt</b> App:`
      },
      mallorca: {
        headline: "Das Kompendium für Freizeitaktivitäten auf Mallorca",
        teaser: `Die <b>actyvyst Mallorca</b> App bietet dem Urlauber <b>die</b> kostenlose Referenz für seine Freizeitgestaltung am Urlaubsort: 
      <p>Alle Freizeitmöglichkeiten auf Mallorca sind in einer einfach zu bedienenden App 
      für Smartphones und Tablets zu finden und - je nach Verfügbarkeit - auch direkt online buchbar.</p>`,
        forProvidersTeaser:
          "Sie bieten Freizeitaktivitäten auf Mallorca an und möchten kostenfrei in der actyvyst App erscheinen?",
        forProvidersQuestionTeaser:
          "Sie möchten mehr über actyvyst Mallorca erfahren? Hier finden Sie Antworten auf die wichtigsten Fragen:",
        forProvidersLink: "Hier geht's direkt zur Anbieterregistrierung",

        topics: {
          content: {
            title: "Wie kommen meine Inhalte in die App?",
            body: `<p style="margin-top:0">Wenn Sie sich als Anbieter registrieren, erhalten Sie Zugang zu unserem <b>Anbieter-Cockpit</b>, in dem Sie Ihre Inhalte komfortabel verwalten können.
          Sie können bis zu fünf verschiedene Aktivitäten anlegen und diese mit Texten, Bildern und Kontaktinformationen beschreiben.</p>
          <p>Wir unterstützen Sie gerne beim Anlegen Ihrer Inhalte: Auf Wunsch helfen wir Ihnen beim Einstellen Ihres Angebots.</p>`
          },
          whitelabel: {
            title:
              "Wie kann ich meine Angebote auf meiner eigenen Website anzeigen?",
            body: `<p style="margin-top:0">In unserem <b>Anbieter-Cockpit</b> können Sie festlegen, ob Ihre Angebote auch als sogenannte Whitelabel-Angebote verfügbar gemacht werden sollen. </p><p>Hier finden Sie <a href="/wldoc/mallorca" target="_blank"> eine detaillierte Anleitung zum Einbinden der Angebote</a></p>`
          },
          booking: {
            title: "Wie kann ich mein Angebot buchbar machen?",
            body: `<p style="margin-top:0">Wenn Sie Ihr Angebot buchbar machen möchten, können Sie für jede Aktivität eine beliebige Anzahl <b>buchbarer Termine</b> erstellen
          und diese mit Preisen hinterlegen. Urlauber können diese Termine dann direkt in der App buchen und bezahlen.
          Mit der <b>actyvyst Anbieter App</b> werden Sie in Echtzeit über alle Buchungen informiert und behalten so jederzeit den Überblick über Ihre Reservierungen.</p>
          <p>Wir beraten Sie gerne bei der Planung für Ihre Online-Buchbarkeit: <a href="mailto:contact@actyvyst.com">Kontaktieren Sie uns</a></p>
          `
          },
          payment: {
            title: "Was kostet mich die Teilnahme?",
            body: `<p style="margin-top:0">Das Einstellen von Inhalten in die <b>actyvyst Mallorca</b> App ist für Anbieter <b>komplett kostenfrei</b>.<br>
          Wenn Sie Ihr Angebot online buchbar machen möchten, fallen auch hierfür <b>keine Fixkosten</b> an - wir finanzieren uns lediglich durch eine
          anteilige Buchungsprovision.</p>`
          },
          marketing: {
            title: "Wie wird die App beworben?",
            body: `<p style="margin-top:0">Wir bewerben die <b>actyvyst Mallorca</b> App genau dann, wenn sie für Urlauber interessant wird: 
          Zum Zeitpunkt der Ankunft auf der Insel.</p>
          <p>Unsere Onlinekampagnen werden ausgespielt, wenn der Urlauber sich auf Mallorca oder im direkten Umfeld befindet.
          Der Urlauber wird direkt erreicht, sobald er auf Google z.B. nach Bootstouren, Surfen oder Aktivitäten für Kinder sucht.
          </p>
          <p>Flankiert werden diese Maßnahmen von diversen Flyer-Kampagnen und Plakataktionen.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Mallorca</b> ist in Kürze online!<br/> Machen Sie sich vorab bereits ein Bild  
        und testen Sie die <b>actyvyst Sylt</b> App:`
      },
      texel: {
        headline: "Das Kompendium für Freizeitaktivitäten auf Texel",
        teaser: `Die <b>actyvyst Texel</b> App bietet dem Urlauber <b>die</b> kostenlose Referenz für seine Freizeitgestaltung am Urlaubsort: 
      <p>Von der Austernfahrt bis zur Paintball-Action sind alle Freizeitmöglichkeiten in einer einfach zu bedienenden App 
      für Smartphones und Tablets zu finden und - je nach Verfügbarkeit - auch direkt online buchbar.</p>`,
        forProvidersTeaser:
          "Sie bieten Freizeitaktivitäten auf Texel an und möchten kostenfrei in der actyvyst Texel App erscheinen?",
        forProvidersQuestionTeaser:
          "Sie möchten mehr über actyvyst Texel erfahren? Hier finden Sie Antworten auf die wichtigsten Fragen:",
        forProvidersLink: "Hier geht's direkt zur Anbieterregistrierung",

        topics: {
          content: {
            title: "Wie kommen meine Inhalte in die App?",
            body: `<p style="margin-top:0">Wenn Sie sich als Anbieter registrieren, erhalten Sie Zugang zu unserem <b>Anbieter-Cockpit</b>, in dem Sie Ihre Inhalte komfortabel verwalten können.
          Sie können bis zu fünf verschiedene Aktivitäten anlegen und diese mit Texten, Bildern und Kontaktinformationen beschreiben.</p>
          <p>Wir unterstützen Sie gerne beim Anlegen Ihrer Inhalte: Auf Wunsch helfen wir Ihnen beim Einstellen Ihres Angebots.</p>`
          },
          booking: {
            title: "Wie kann ich mein Angebot buchbar machen?",
            body: `<p style="margin-top:0">Wenn Sie Ihr Angebot buchbar machen möchten, können Sie für jede Aktivität eine beliebige Anzahl <b>buchbarer Termine</b> erstellen
          und diese mit Preisen hinterlegen. Urlauber können diese Termine dann direkt in der App buchen und bezahlen.
          Mit der <b>actyvyst Anbieter App</b> werden Sie in Echtzeit über alle Buchungen informiert und behalten so jederzeit den Überblick über Ihre Reservierungen.</p>
          <p>Wir beraten Sie gerne bei der Planung für Ihre Online-Buchbarkeit: <a href="mailto:contact@actyvyst.com">Kontaktieren Sie uns</a></p>
          `
          },
          payment: {
            title: "Was kostet mich die Teilnahme?",
            body: `<p style="margin-top:0">Das Einstellen von Inhalten in die <b>actyvyst Texel</b> App ist für Anbieter <b>komplett kostenfrei</b>.<br>
          Wenn Sie Ihr Angebot online buchbar machen möchten, fallen auch hierfür <b>keine Fixkosten</b> an - wir finanzieren uns lediglich durch eine
          anteilige Buchungsprovision.</p>`
          },
          marketing: {
            title: "Wie wird die App beworben?",
            body: `<p style="margin-top:0">Wir bewerben die <b>actyvyst Texel</b> App genau dann, wenn sie für Urlauber interessant wird: 
            Zum Zeitpunkt der Ankunft auf der Insel.
            Unsere Onlinekampagnen werden ausgespielt, wenn der Urlauber sich auf Texel oder im direkten Umfeld befindet.
            Der Urlauber wird direkt erreicht, sobald er auf Google z.B. nach Reiten, Surfen oder Aktivitäten für Kinder sucht.
            </p>
            <p>Flankiert werden diese Maßnahmen von diversen Flyer-Kampagnen und Plakataktionen.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Texel</b> ist in Kürze online!<br/> Machen Sie sich vorab bereits ein Bild  
        und testen Sie die <b>actyvyst Sylt</b> App:`
      },
      allgaeu: {
        headline: "Das Kompendium für Freizeitaktivitäten im Allgäu",
        teaser: `Die <b>actyvyst Allgäu</b> App bietet dem Urlauber <b>die</b> kostenlose Referenz für seine Freizeitgestaltung am Urlaubsort: 
      <p>Vom Abenteuer-Schlucht-Canyoning bis zum Wakeboarding sind alle Freizeitmöglichkeiten in einer einfach zu bedienenden App 
      für Smartphones und Tablets zu finden und - je nach Verfügbarkeit - auch direkt online buchbar.</p>`,
        forProvidersTeaser:
          "Sie bieten Freizeitaktivitäten im Allgäu an und möchten kostenfrei in der actyvyst Allgäu App erscheinen?",
        forProvidersQuestionTeaser:
          "Sie möchten mehr über actyvyst Allgäu erfahren? Hier finden Sie Antworten auf die wichtigsten Fragen:",
        forProvidersLink: "Hier geht's direkt zur Anbieterregistrierung",

        topics: {
          content: {
            title: "Wie kommen meine Inhalte in die App?",
            body: `<p style="margin-top:0">Wenn Sie sich als Anbieter registrieren, erhalten Sie Zugang zu unserem <b>Anbieter-Cockpit</b>, in dem Sie Ihre Inhalte komfortabel verwalten können.
          Sie können bis zu fünf verschiedene Aktivitäten anlegen und diese mit Texten, Bildern und Kontaktinformationen beschreiben.</p>
          <p>Wir unterstützen Sie gerne beim Anlegen Ihrer Inhalte: Auf Wunsch helfen wir Ihnen beim Einstellen Ihres Angebots.</p>`
          },
          booking: {
            title: "Wie kann ich mein Angebot buchbar machen?",
            body: `<p style="margin-top:0">Wenn Sie Ihr Angebot buchbar machen möchten, können Sie für jede Aktivität eine beliebige Anzahl <b>buchbarer Termine</b> erstellen
          und diese mit Preisen hinterlegen. Urlauber können diese Termine dann direkt in der App buchen und bezahlen.
          Mit der <b>actyvyst Anbieter App</b> werden Sie in Echtzeit über alle Buchungen informiert und behalten so jederzeit den Überblick über Ihre Reservierungen.</p>
          <p>Wir beraten Sie gerne bei der Planung für Ihre Online-Buchbarkeit: <a href="mailto:contact@actyvyst.com">Kontaktieren Sie uns</a></p>
          `
          },
          payment: {
            title: "Was kostet mich die Teilnahme?",
            body: `<p style="margin-top:0">Das Einstellen von Inhalten in die <b>actyvyst Allgäu</b> App ist für Anbieter <b>komplett kostenfrei</b>.<br>
          Wenn Sie Ihr Angebot online buchbar machen möchten, fallen auch hierfür <b>keine Fixkosten</b> an - wir finanzieren uns lediglich durch eine
          anteilige Buchungsprovision.</p>`
          },
          marketing: {
            title: "Wie wird die App beworben?",
            body: `<p style="margin-top:0">Wir bewerben die <b>actyvyst Allgäu</b> App genau dann, wenn sie für Urlauber interessant wird: 
              Zum Zeitpunkt der Anreise.
              Unsere Onlinekampagnen werden ausgespielt, wenn der Urlauber sich im Allgäu oder im direkten Umfeld befindet.
              Der Urlauber wird direkt erreicht, sobald er auf Google z.B. nach Reiten, Klettern oder Aktivitäten für Kinder sucht.</p>
              <p>Flankiert werden diese Maßnahmen von diversen Flyer-Kampagnen und Plakataktionen im Zielgebiet.</p>`
          }
        },
        appStoreTeaser: `<b>actyvyst Allgäu</b> ist in Kürze online!<br/> Machen Sie sich vorab bereits ein Bild  
        und testen Sie die <b>actyvyst Sylt</b> App:`
      }
    },
    WhiteLabelHowTo: {
      Headline:
        "Einbindung der actyvyst Whitelabel-Buchungsstrecke in Ihrer Website",
      Intro:
        "Mit der folgenden Anleitung können Sie gezielt Angebote, die Sie in den actyvyst Apps veröffentlicht haben, auf Ihrer Website buchbar machen.",
      Step1Title: "Schritt 1",
      Step1Intro:
        "Kopieren Sie den folgenden Script-Tag und fügen Sie ihn innerhalb des {`<head>`}-Elements Ihrer Website ein:",
      Step1CodeBlock:
        "<script type='text/javascript'>\n   var h = document.querySelector('head');\n   var actyScript = document.createElement('script'); \n   let resourcePath = 'https://{{destination}}.actyvyst.de/wl/actylauncher.js?pid=<Ihre Provider-ID>&destination={{destination}}'; \n actyScript.src = resourcePath + '&v=' + Date.now(); \n  h.append(actyScript);\n</script>",
      Step1Explanation:
        "**Bitte beachten Sie, dass Sie <Ihre Provider-ID> mit Ihrer korrekten ID ersetzen!**\n\nDiese können Sie im Profil Ihres actyvyst-Cockpit-Accounts kopieren und an der markierten Stelle einsetzen.",
      Step2Title: "Schritt 2",
      Step2Intro:
        "Platzieren Sie beliebig viele der folgenden Elemente innerhalb des {`<body>`}-Tags Ihrer Website, um Ihre Angebote auf verschiedene Weise zu präsentieren:",
      Step2CodeBlockTitle: "actyvyst Button",
      Step2CodeBlock:
        "<div class='actyvystButton' data-buttonTitle='Ponyreiten für Kids'></div>",
      Step2Explanation:
        "Dieses Element erzeugt einen Button mit einer Verlinkung auf alle Angebote, die Sie bei actyvyst veröffentlicht haben. Der Text des Buttons wird mit dem Attribut `data-buttonTitle` definiert. Mit einem weiteren Attribut (`data-deeplink`) können Sie das Link-Level bestimmen:",
      Step2CodeBlockBTitle: "Angebots-Detail-Seite",
      Step2CodeBlockCTitle: "Angebots-Termine-Seite",
      Step2CodeBlockDTitle: "actyvyst Link",
      Step2CodeBlockB:
        "<div class='actyvystButton' \n   data-deeplink='deine-stunde-mit-dem-pony/?a_id=60d184f6421c448cec847196' \n   data-buttonTitle='Ponyreiten für Kids'>\n</div>",
      Step2CodeBlockC:
        "<div class='actyvystButton' \n   data-deeplink='variants/61cc51303910c943509dc605' \n   data-buttonTitle='Ponyreiten für Kids'>\n</div>",
      Step2CodeBlockD:
        "<a class='actyvystLink' data-deeplink='deine-stunde-mit-dem-pony/?a_id=60d184f6421c448cec8471963'>Ponyreiten für Kinder</a>",
      Step2ExplanationD:
        "Dieses Element erzeugt einen einfachen Link (**`<a href...>`**), mit dem Sie direkt auf eine Seite in der actyvyst Whitelabel Buchungsstrecke verlinken können. Auch hier wird das Link-Level über das **`data-deeplink`**-Attribut gesteuert.",
      Step2ExplanationB:
        "Die jeweiligen Deeplinks können Sie aus der actyvyst-Website kopieren, in der Ihre Angebote zu finden sind. Die Buttons benötigen eine CSS-Klasse mit dem Namen **`actyvystButton`**, können aber auch mehrere individuelle CSS-Klassen besitzen, z.B. **`some other actyvystButton`**."
    }
  }
};

export default de;
