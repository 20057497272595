import { makeStyles } from "@material-ui/core/styles";

const useGlobalClasses = () => {
    return makeStyles((theme) => ({
        productImage: {
            borderRadius: theme.shape.borderRadius
        },
        galleryImage: {
            borderRadius: 5
        },
        detailHeadline: {
            marginTop: 10
        },
        cardImage: {
            height: 180
        },
        cardContent: {
            height: 40,
            position: "absolute",
            bottom: 0,
            backgroundColor: "#ffffff",
            width: "100%"
        },
        cardTitle: {
            marginTop: -10,
            padding: 0,
            paddingTop: 0,
            lineClamp: 1,
            maxLines: 1
        },
        cardSubTitle: {
            marginTop: -2,
            padding: 0,
            paddingTop: 0
        },
        offerCard: {
            padding: 0,
            marginBottom: 20,
            height: 210
        },
        secondaryColor: {
            color: theme.palette.secondary.main
        },
        toolBar: {
            maxHeight: 30,
            backgroundColor: "transparent",
            paddingBottom: 10,
            paddingTop: 10
        },
        footerBar: {
            maxHeight: 50,
            backgroundColor: "white",
            top: "auto",
            bottom: 0
        },
        appBar: {
            height: 60,
            width: "100%",
            margin: 0,
            backgroundColor: theme.palette.secondary.background,
            color: theme.palette.primary.main,
            marginBottom: 20,
            marginTop: 0
        },
        contactDialog: {
            width: "100%"
        },
        galleryGridList: {
            flexWrap: "nowrap",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)"
        },
        galleryTileTitle: {
            color: "white",
            fontSize: "0.8rem"
        },
        galleryTitleBar: {
            height: 20,
            background:
                "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
        },
        offerList: {
            marginBottom: 20,
            marginTop: 20
        }
    }))
};

export default useGlobalClasses;
