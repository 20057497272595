import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ForgotPasswordFormUI from "./FormUI";
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const ForgotPasswordFormController = (props) => {
  const { t } = useTranslation();
  const handleSubmit = (values, { setSubmitting }) => {
    const requestPasswordReset = async () => {
      try {
        setSubmitting(true);
        await axios.post("/auth/requestpasswordreset", {
          challengeType: "uuid",
          email: values.email,
        });
        setSubmitting(false);
        props.onSuccess(values);
      } catch (err) {
        setSubmitting(false);
        props.onError(values, err);
      }
    };
    requestPasswordReset();
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .max(50, t("generic.validation.max", { max: 50 }))
          .email(t("ForgotPassword.validation.email")),
      })}
      validateOnBlur={true}
      validateOnChange={false}
      render={(formikProps) => {
        return <ForgotPasswordFormUI {...formikProps} />;
      }}
    />
  );
};

export default ForgotPasswordFormController;
